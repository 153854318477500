import "./style.css"
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import GetWork from "../Home/components/get_work/GetWork";
import services_pic1 from "../../assets/images/services_pic1.svg";
import BrowseSkill from "./components/browse_skill/BrowseSkill";

export default function Services() {
    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="services_block1 d_flex justify_content_space_between align_items_center">
                            <div className="services_block1_1">
                                <h2 className="fs_38 f_600 c_blue">Our Service</h2>
                                <div className="fs_16 f_500 text_capitalize">Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vilDu kan vara
                                    ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vilDu kan vara ddrabbad.
                                    drabbad drabbad drabbadrabbaddrabbad. Dick vilDu kan vara ddrabbad. drabbad drabbad
                                    drabbadrabbaddrabbad. Dick vil
                                </div>
                            </div>
                            <div className="services_block1_2">
                                <img src={services_pic1} alt="services_pic1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <GetWork/>
                <BrowseSkill/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}