import "./style.css";
import logo from "../../assets/images/logo.svg"
import {NavLink, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useLocation} from 'react-router-dom';
import notification_icon from "../../assets/images/notification_icon.svg";
import user_pic from "../../assets/images/user_pic.svg";


export default function Header() {
    let navigate = useNavigate()
    let [token, setToken] = useState(localStorage.getItem("token") || "");
    const location = useLocation();

    return (
        <>
            {
                (location.pathname === "/dashboard") ||
                (location.pathname === "/dashboard/notifications") ||
                (location.pathname === "/messages") ||
                (location.pathname === "/tasks") ||
                (location.pathname === "/favourites") ||
                (location.pathname === "/payment_history") ||
                (location.pathname === "/payment_method")
                    ?
                    <div className="header_dashboard">
                        <div className="header_dashboard_container">
                            <div className="container_inner">
                                <div
                                    className="header_dashboard_cont d_flex align_items_center justify_content_space_between">
                                    <div
                                        className="dashboard_header_b1 d_flex align_items_center justify_content_space_between">
                                        <div className="header_search_input_b">
                                            <input type="text" placeholder="Search" className="fs_16"/>
                                        </div>
                                    </div>

                                    <div className="d_flex align_items_center notification_block">
                                        <button className="notification_icon_b" onClick={() => navigate("/dashboard/notifications")}>
                                            <button><img src={notification_icon} alt=""/></button>
                                        </button>
                                        <NavLink
                                            className={({isActive}) => isActive ? 'header_navLink_active c_blue fs_16 f_500' : 'header_navLink fs_16 f_500'}
                                            to="/messages">Messages</NavLink>
                                        <button className="user_pic_b"><img src={user_pic} alt=""/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :

                (!(location.pathname === "/login") && !(location.pathname === "/register_client") && !(location.pathname === "/register_provider")
                    && !(location.pathname === "/register_client/verify_email")
                    && !(location.pathname === "/register_provider/personal_info")
                    && !(location.pathname === "/register_provider/account_verification")
                    && !(location.pathname === "/login/forget_password")
                    && !(location.pathname === "/login/onetime_password")
                    && !(location.pathname === "/login/reset_password")
                )
                    ?
                    <div className="header">
                        <div className="container">
                            <div className="container_inner">
                                <div className="header_cont d_flex align_items_center justify_content_space_between">
                                    <div onClick={() => navigate("/")}>
                                        <img src={logo} alt="" className="logo"/>
                                    </div>
                                    <div className="d_flex align_items_center header_navlinks_cont">
                                        <div className="d-flex align-items-center justify-content-space-between">
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_navLink_active  c_blue fs_16 f_500' : 'header_navLink fs_16 f_500'}
                                                to="/">Home</NavLink>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_navLink_active  c_blue fs_16 f_500' : 'header_navLink fs_16 f_500'}
                                                to="/about">About Us</NavLink>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_navLink_active  c_blue fs_16 f_500' : 'header_navLink fs_16 f_500'}
                                                to="/works">How it Works</NavLink>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_navLink_active  c_blue fs_16 f_500' : 'header_navLink fs_16 f_500'}
                                                to="/services">Our Services</NavLink>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_navLink_active  c_blue fs_16 f_500' : 'header_navLink fs_16 f_500'}
                                                to="/contactus">Contact Us</NavLink>
                                        </div>
                                        <div className="login_register_btn_block">
                                            <button className="fs_16 c_blue sign_in_btn f_500"
                                                    onClick={() => navigate('/login')}>Sign in
                                            </button>
                                            <button className="bc_blue fs_16 c_white register_btn f_500"
                                                    onClick={() => navigate("/register_as")}>Register Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="header">
                        <div className="container">
                            <div className="container_inner">
                                <div className="header_cont d_flex align_items_center justify_content_start">
                                    <div onClick={() => navigate("/")}>
                                        <img src={logo} alt="" className="logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}