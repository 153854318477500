import "./style.css"
import Header from "../../components/header/Header";
import {useNavigate} from "react-router-dom";
 import MyTimer from "./timer/Timer";
import { useTimer } from 'react-timer-hook';
import {useEffect, useState} from "react";
import VerificationInput from "react-verification-input";

export default function AccountVerification({expiryTimestamp}){
    let navigate=useNavigate();
    const time = new Date();
    const [restartStatus, setRestartStatus] = useState(true)

    return(
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="verify_cont_gen  bc_white">
                            <div className="verify_cont d_flex justify_content_center fd_column">
                                <h1 className="text_capitalize text_center fs_38 f_600 c_blue">Account verification</h1>
                                <div className="text_center fs_16 f_500 text_capitalize margin_top_16">
                                    enter the verification code sent to <span>steveso***@gmail.com</span> & <span>07041****43</span>
                                </div>
                                <div className="code_block d_flex align_items_center justify_content_space_between margin_top_48">
                                    <VerificationInput length={4}
                                                       classNames={{
                                                           container: "container",
                                                           character: "character",
                                                           characterInactive: "character--inactive",
                                                           characterSelected: "character--selected",
                                                       }}
                                    />
                                </div>

                                <div className="timer_block">
                                    <MyTimer restartStatus={restartStatus}/>
                                </div>
                                <div className="d_flex  justify_content_center align_items_center margin_top_48 verify_btn_block">
                                    {/*{error ? <p className="error">{error}</p> : null}*/}
                                    <button className="bc_blue c_white fs_16 f_500 verify_btn_block_btn"
                                            onClick={()=>navigate("/login")}
                                    >Verify</button>
                                </div>
                                <div className="d_flex justify_content_center align_items_center receive_code_b text_center text_capitalize" onClick={() => setRestartStatus(!restartStatus)}>
                                    didnt receive code? <span >Resend code</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}