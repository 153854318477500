import "./style.css";
import {useState} from "react";
import Steward_pic from "../../../../assets/images/Steward_pic.svg";
import Warren_pic from "../../../../assets/images/Warren_pic.svg";
import Howard_pic from "../../../../assets/images/Howard_pic.svg";
import Leslie_pic from "../../../../assets/images/Leslie_pic.svg";
import Simmons_pic from "../../../../assets/images/Simmons_pic.svg";
import Fox_pic from  "../../../../assets/images/Fox_pic.svg";
import Pagination from "../../../../components/pagination/Pagination";


export default function Superheroes(props) {
    let [page, setPage]=useState(0)
    let [heroes, setHeroes] = useState([
        {
            id: 1,
            img:Steward_pic,
            name: "Darrell Steward",
            position: "Web Designer",
        },
        {
            id: 2,
            img:Warren_pic,
            name: "Wade Warren",
            position: "President of Sales",
        },
        {
            id: 3,
            img:Howard_pic,
            name: "Esther Howard",
            position: "Marketing Coordinator",
        },
        {
            id: 4,
            img:Leslie_pic,
            name: "Leslie Alexander",
            position: "Web Designer",
        },
        {
            id: 5,
            img:Simmons_pic,
            name: "Brooklyn Simmons",
            position: "Marketing Coordinator",
        },
        {
            id: 6,
            img:Fox_pic,
            name: "Robert Fox",
            position: "Web Designer",
        },
        {
            id: 1,
            img:Steward_pic,
            name: "Darrell Steward",
            position: "Web Designer",
        },
        {
            id: 2,
            img:Warren_pic,
            name: "Wade Warren",
            position: "President of Sales",
        },
        {
            id: 3,
            img:Howard_pic,
            name: "Esther Howard",
            position: "Marketing Coordinator",
        },
        {
            id: 4,
            img:Leslie_pic,
            name: "Leslie Alexander",
            position: "Web Designer",
        },
        {
            id: 5,
            img:Simmons_pic,
            name: "Brooklyn Simmons",
            position: "Marketing Coordinator",
        },
        {
            id: 6,
            img:Fox_pic,
            name: "Robert Fox",
            position: "Web Designer",
        },
    ])

    let onChangePage = (page) => {
       setPage(page.nextSelectedPage)
    }


    return (
        <div className="superHeroes_cont bc_white">
            <div className="container">
                <div className="container_inner">
                    <h1 className="text_center fs_38 f_600 c_blue">Meet the Superheroes</h1>
                    <div className="superHeroes_cont_blocks d_grid grid_columns_3fr">
                        {
                            heroes.map((item,index)=>{
                                if(page <= index && index < page + 6 ){
                                    return(
                                        <div className="superHeroes_cont_block d_flex justify_content_center  align_items_center fd_column" key={item.id}>
                                            <img src={item.img} alt="superheroes_pic"/>
                                            <h3 className="c_black fs_24 f_500">{item.name}</h3>
                                            <span className="fs_16 c_grey f_500">{item.position}</span>
                                        </div>
                                    )
                                }

                            })
                        }
                    </div>
                    <Pagination  heroes={heroes} onChangePage={onChangePage}/>
                </div>
            </div>
        </div>
    )
}