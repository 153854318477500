import "./style.css";
import {useState} from "react";
import skill_icon from "../../../../assets/images/skill_icon.svg";
import payment_icon from "../../../../assets/images/payments_icon.svg";
import flexible_prices_icon from "../../../../assets/images/flexible_prices_icon.svg";
import arrow_right from "../../../../assets/images/arrow_right_blue.png";

export default function FeelSecure() {
    let [fellSecure, setFellSecure]=useState([
        {
            id:1,
            img:skill_icon,
            title:"Verified Skill providers",
            desc:"We offer trusted, relaxed, verified and competent service providers",
            more: "Learn More",
        },
        {
            id:2,
            img:payment_icon,
            title:"Secure Payments",
            desc:"Get peace of mind when paying from the comfort of your own home with our trusted payment gateways",
            more: "Learn More",
        },
        {
            id:3,
            img:flexible_prices_icon,
            title:"Flexible Prices",
            desc:"Our service pricing is flexible. Get flexible pricing from our service provider.",
            more: "Learn More",
        },
    ])
    return (
        <div className="container">
            <div className="container_inner">
                <div className="feel_secure_cont margin_bottom_96">
                    <h1 className="fs_38 f_600 c_blue text_center">Feel Secure and Safe</h1>
                    <div className="d_flex align_items_center justify_content_space_between feel_secure_cont_blocks">
                        {fellSecure.map((item,index)=>{
                                return(
                                    <div className="feel_secure_cont_block d_flex fd_column justify_content_center align_items_center" key={item.id}>
                                        <img src={item.img} alt="icon" className="feel_secure_cont_block_img"/>
                                        <h2 className="fs_28 f_600 c_black text_center">{item.title}</h2>
                                        <div className="fs_16 f_500 text_center">{item.desc}</div>
                                        <button className="c_blue f_600 d_flex align_items_center learn_more_btn">{item.more}<img src={arrow_right} alt="arrow_right"/></button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}