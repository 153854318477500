import "./style.css";
import {useState} from "react";
import people_choose_pic from "../../../../assets/images/people_choose_pic.svg";
import credibility_icon from "../../../../assets/images/credibility_icon.svg";
import security_icon from "../../../../assets/images/security_icon.svg";
import value_icon from "../../../../assets/images/Value_icon.svg";
import support_icon from "../../../../assets/images/support_icon.svg";

export default function PeopleChoose(){
    let [peopleChoose, setPeopleChoose]=useState([
        {
            id:1,
            img:credibility_icon,
            title:"Credibility",
            desc:"we verified skill providers and publish their feedback ratings and reviews to help clients identified professionals accross the website.",
        },
        {
            id:2,
            img:security_icon,
            title:"Security",
            desc:"we offer safe payment gateway protection and different choice of preferred payment method for financial peace of mind.",
        },
        {
            id:3,
            img:value_icon,
            title:"Value",
            desc:"we have the lowest fees in the industry, we provide you with maximum value at minimum cost.",
        },
        {
            id:4,
            img:support_icon,
            title:"Support",
            desc:"our dedicated support team works 24/7 to resolve all of your enquiries via email or phone no matter your location",
        },
        ])
    return(
        <div className="people_choose_container margin_bottom_96">
            <h1 className="text_center fs_38 f_600 c_blue">Why People Choose Everiwork</h1>
            <div className="people_choose_cont bc_darkBlue d_flex ">
                <div className="people_choose_pic_block">
                    <img src={people_choose_pic} alt="people_choose" className="people_choose_pic"/>
                </div>
                <div className="d_flex fd_column justify_content_center people_choose_cont_block2">
                    {peopleChoose.map((item,index)=>{
                        return(
                            <div className="people_choose_cont_block2_item d_flex align_items_center justify_content_space_between" key={item.id}>
                                <div  className="people_choose_cont_block2_item_pic d_flex justify_content_center align_items_center">
                                    <img src={item.img} alt="icon"/>
                                </div>
                                <div className="d_flex fd_column justify_content_start">
                                    <span className="fs_28 f_600 c_black">{item.title}</span>
                                    <div className="fs_16 c_grey text_capitalize">{item.desc}</div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>

        </div>
    )
}