import "./style.css";
import evriwork_payment_pic from "../../../../assets/images/evriwork_pay_pic.svg";
import free_payment_icon from "../../../../assets/images/free_payment_icon.svg";
import cashiess_payment_icon from "../../../../assets/images/cashiness_payment_icon.svg";
import control_icon from "../../../../assets/images/control_icon.svg";


export default function EvriworkPayment() {
    return (
        <div className="container">
            <div className="container_inner">
                <div className="evriwork_payment_cont d_flex fd_column justify_content_center ">
                    <h2 className="text_center fs_38 f_600 c_blue">Evriwork Payment</h2>
                    <div
                        className="d_flex justify_content_space_between align_items_center evriwork_payment_cont_block1 margin_top_48">
                        <img src={evriwork_payment_pic} alt="evriwork_payment_pic" className="evriwork_payment_pic"/>
                        <div className="d_flex fd_column justify_content_start evriwork_payment_cont_block_text_b">
                            <div className="fs_16 f_500">Lörem ipsum desmeter seväfatt. Monofaksk besm inte ryr. Fåsm
                                filokemi fast autosat. Du
                                kan vara drabbad. Dick viLörem ipsum desmeter seväfatt. Monofaksk besm inte ryr. Fåsm
                                filokemi fast autosat. Du kan vara drabbad. Lörem ipsum desmeter seväfatt. Monofaksk
                                besm inte ryr. Fåsm filokemi fast autosat. Du kan vara drabbad. Dick viLörem ipsum
                                desmeter seväfatt. Monofaksk besm inte ryr. Fåsm filokemi fast autosat. Du kan vara
                                drabbad. Dick
                            </div>
                            <button className="fs_16 f_500 bc_green c_white learn_more_btn">Learn more</button>
                        </div>
                    </div>
                    <div className="d_grid grid_columns_3fr  evriwork_payment_cont_block2 margin_top_48">
                        <div
                            className="d_flex fd_column align_items_center justify_content_center  evriwork_payment_cont_block2_item">
                            <img src={free_payment_icon} alt="free_payment_icon"/>
                            <p className="fs_16 f_500 text_center">Fast and hassle free payment</p>
                        </div>
                        <div
                            className="d_flex fd_column align_items_center justify_content_center  evriwork_payment_cont_block2_item">
                            <img src={cashiess_payment_icon} alt="cashiess_payment_icon"/>
                            <p className="fs_16 f_500 text_center">Cashless payments,
                                no cash at hand</p>
                        </div>
                        <div
                            className="d_flex fd_column align_items_center justify_content_center  evriwork_payment_cont_block2_item">
                            <img src={control_icon} alt="control_icon"/>
                            <p className="fs_16 f_500 text_center">You are always in control</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

