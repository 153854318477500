import "./style.css";
import {useState} from "react";
import collaboration_img from "../../../../assets/images/collaboration_img.svg";
import star_pic from "../../../../assets/images/star_pic.svg";
import arrow_right from "../../../../assets/images/arrow_right_blue.png";

export default function ExploreServices() {
    const [popServices, setPopServices] = useState([
        {
            id:1,
            img: collaboration_img,
            title: "Steve Solomon/Content Writer",
            desc: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil drabbadrabbaddrabbad. Dick vil",
            starsCount: 5,
            starPic: star_pic,
            queueCount: 2,
            learnMore: "Learn More",
        },
        {
            id:2,
            img: collaboration_img,
            title: "Steve Solomon/Content Writer",
            desc: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil drabbadrabbaddrabbad. Dick vil",
            starsCount: 5,
            starPic: star_pic,
            queueCount: 2,
            learnMore: "Learn More",
        },
        {
            id:3,
            img: collaboration_img,
            title: "Steve Solomon/Content Writer",
            desc: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil drabbadrabbaddrabbad. Dick vil",
            starsCount: 5,
            starPic: star_pic,
            queueCount: 2,
            learnMore: "Learn More",
        },
        {
            id:4,
            img: collaboration_img,
            title: "Steve Solomon/Content Writer",
            desc: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil drabbadrabbaddrabbad. Dick vil",
            starsCount: 5,
            starPic: star_pic,
            queueCount: 2,
            learnMore: "Learn More",
        },
        {
            id:5,
            img: collaboration_img,
            title: "Steve Solomon/Content Writer",
            desc: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil drabbadrabbaddrabbad. Dick vil",
            starsCount: 5,
            starPic: star_pic,
            queueCount: 2,
            learnMore: "Learn More",
        },
        {
            id:6,
            img: collaboration_img,
            title: "Steve Solomon/Content Writer",
            desc: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil drabbadrabbaddrabbad. Dick vil",
            starsCount: 5,
            starPic: star_pic,
            queueCount: 2,
            learnMore: "Learn More",
        },
    ])
    return (
        <div className="container">
            <div className="container_inner">
                <div className="explore_services_cont margin_bottom_96">
                    <h1 className="fs_38 f_600 text_center c_blue">Explore popular services</h1>
                    <div className="explore_services_cont_items_block d_grid grid_gab_35 grid_columns_3fr margin_bottom_96">
                        {
                            popServices.map((item, index) => {
                                return (
                                    <div
                                        className="explore_services_cont_item d_flex justify_content_center align_items_center fd_column" key={item.id}>
                                        <img src={item.img} alt="explore_services_pic"
                                             className="explore_services_pic"/>
                                        <h2 className="fs_28 f_600 text_center c_black">{item.title}</h2>
                                        <div className="text_center text_capitalize fs_16 f_500">{item.desc}</div>
                                        <div className="stars_block">
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                        </div>
                                        <div>{item.queueCount} in Queue</div>
                                        <button className="d_flex align_items_center fs_16 learn_more_btn">Learn More <img src={arrow_right} alt="arrow_right"/></button>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="d_flex justify_content_center align_items_center explore_more_btn_block margin_bottom_96">
                        <button className="explore_more_btn bc_green c_white fs_16 f_500">Explore More</button>
                    </div>
                </div>
            </div>
        </div>
    )
}