import "./style.css";
import pers_info_icon from "../../../../assets/images/pers_info_icon.svg";
import identification_icon from "../../../../assets/images/indentification_icon.svg";
import gg_profile_icon from "../../../../assets/images/gg_profile_icon.svg";
import {useState} from "react";
import PersonalInfo from "../../PersonalInfo";
import PersonalInformation from "../personal_information/PersonalInformation";
import Identification from "../identification/Identification";
import ProfilePicture from "../profile_picture/ProfilePicture";
import Loading from "../loading/Loading";
import LoadingModal from "../loading_modal/LoadingModal";

export default function RegisterProviderSteps() {
    const [activeStep, setActiveStep] = useState(0);

    let changeStep = (type) => {
        if(type==='next'){
            setActiveStep(activeStep + 1)
        }
        if(type === 'back'){
            setActiveStep(activeStep - 1)
        }
    }
    let currentStep = () => {
        switch (activeStep) {
            case 0:
                return <PersonalInformation changeStep={changeStep}/>
            case 1:
                return <Identification changeStep={changeStep} />;
            case 2:
                return < ProfilePicture changeStep={changeStep} />;
            case 3:
                return <LoadingModal changeStep={changeStep} />;
        }
    }

    return (
        <div className="container">
            <div className="container_inner">
                <div className="reg_prov_steps_cont d_flex align_items_center justify_content_space_between">
                    <div className={activeStep >= 0 ? "activeStep d_flex fd_column align_items_center justify_content_center" : "reg_prov_step d_flex fd_column align_items_center justify_content_center"}>
                        <div className={activeStep >= 0 ? "activeStep_reg_prov_step_circle bc_blue d_flex justify_content_center align_items_center":"reg_prov_step_circle d_flex justify_content_center align_items_center"}>
                            <img src={pers_info_icon} alt=""/>
                        </div>
                        <p className={activeStep >=0 ? "activeStep_reg_prov_step_title f_500 fs_16 c_blue ":"reg_prov_step_title f_500 fs_16"}>Personal Information</p>
                    </div>
                    <div className="reg_prov_step_line">
                    </div>
                    <div className={activeStep >= 1 ? "activeStep d_flex fd_column align_items_center justify_content_center" : "reg_prov_step d_flex fd_column align_items_center justify_content_center"}>
                        <div className={activeStep >= 1 ? "activeStep_reg_prov_step_circle bc_blue d_flex justify_content_center align_items_center":"reg_prov_step_circle d_flex justify_content_center align_items_center"}>
                            <img src={identification_icon} alt=""/>
                        </div>
                        <p className={activeStep >=1 ? "activeStep_reg_prov_step_title f_500 fs_16 c_blue ":"reg_prov_step_title f_500 fs_16"}>Identification</p>
                    </div>
                    <div className="reg_prov_step_line">
                    </div>
                    <div className={activeStep >= 2 ? "activeStep d_flex fd_column align_items_center justify_content_center" : "reg_prov_step d_flex fd_column align_items_center justify_content_center"}>
                        <div className={activeStep >= 2 ? "activeStep_reg_prov_step_circle bc_blue d_flex justify_content_center align_items_center":"reg_prov_step_circle d_flex justify_content_center align_items_center"}>
                            <img src={gg_profile_icon} alt=""/>
                        </div>
                        <p className={activeStep >= 2? "activeStep_reg_prov_step_title f_500 fs_16 c_blue ":"reg_prov_step_title f_500 fs_16"}>Profile Picture</p>
                    </div>
                </div>
                {currentStep()}
            </div>
        </div>
    )
}