import "./style.css"
import Header from "../../components/header/Header";
import email_icon from "../../assets/images/sms.svg";
import key_icon from "../../assets/images/key.svg";
import {useNavigate} from "react-router-dom";
import facebook_icon from "../../assets/images/facebook_icon.svg";
import google_icon from "../../assets/images/google_icon.svg";
import {useState} from "react";
import {AiOutlineEye} from "react-icons/ai";
import {AiOutlineEyeInvisible} from "react-icons/ai";
import axios from "axios";
import config from "../../config";

export default function Login() {
    let navigate = useNavigate();
    let [show, setShow] = useState(false)
    const [login, setLogin] = useState({
        email: "",
        password: "",
    })
    const [error, setError] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)

    let showHidePass = () => {
        setShow(!show)
    }
    const handleChangeLogin = (e, name) => {
        setLogin({...login, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(login.email).toLowerCase());
    }
    const loginHandler = () => {
        let values={
            email: login.email,
            password: login.password,
        }
        setLoginBtn(true)
        if (login.email.length && login.password.length) {
            if (login.password.length > 7) {
                if (validateEmail()) {
                    axios.post(`${config.url}/login`,values )
                        .then(response => {
                            console.log(response,"response");
                            localStorage.setItem('jwtToken',  response.data.token)
                            navigate("/dashboard")
                        })
                        .catch(error => {
                            console.log(error.response,"error")
                            setError(error.response.data.error)
                        })
                } else {
                    setError("Incorrect email address")
                }
            } else {
                setError("Password length is small")
            }
        }
    }


    return (
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="login_register_cont bc_white">
                            <h1 className="text_capitalize text_center fs_38 f_600">Sign In</h1>
                            <div className="login_register_cont_blocks d_flex fd_column">
                                <div className="login_register_cont_block d_flex fd_column">
                                    <label htmlFor="" className="fs_16 f_500">Email address/Mobile number</label>
                                    <input type="email" placeholder="Enter email address"
                                           style={!login.email && loginBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeLogin(e.target.value, "email")}
                                    />
                                    <img src={email_icon} alt="email_icon"/>
                                </div>
                                <div className="login_register_cont_block d_flex fd_column">
                                    <label htmlFor="" className="fs_16 f_500">Password</label>
                                    <input type={show ? "text" : "password"} placeholder="Password"
                                           style={!login.password && loginBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeLogin(e.target.value, "password")}
                                    />
                                    <img src={key_icon} alt="key_icon"/>
                                    {show ? <AiOutlineEye className="hide_icon fs_16" onClick={showHidePass}/> :
                                        <AiOutlineEyeInvisible className="hide_icon fs_16" onClick={showHidePass}/>
                                    }

                                </div>
                                <div className="d_flex justify_content_space_between remember_forget_pass_block">
                                    <div className="remember_me_block d_flex align_items_center">
                                        <input type="checkbox"/>
                                        <label htmlFor="" className="fs_14 f_500">Remember me?</label>
                                    </div>
                                    <div className="fs_16 f_500 forget_pass" onClick={() => navigate("/login/forget_password")}>Forget
                                        password?
                                    </div>
                                </div>
                            </div>
                            <div className="d_flex fd_column justify_content_center align_items_center margin_top_48">
                                {error ? <p className="error">{error}</p> : null}
                                <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn" onClick={loginHandler}>Sign in to
                                    your account
                                </button>
                            </div>
                            <p className="margin_top_48 fs_24 f_500 text_center c_grey">Or</p>
                            <p className="text_center c_grey sign_in_using fs_18 f_500">Sign In using</p>
                            <div
                                className="d_flex justify_content_center align_items_center login_social_blocks margin_bottom_96">
                                <div>
                                    <a href="https://www.facebook.com" target="_blank"><img src={facebook_icon} alt=""/></a>
                                </div>
                                <div>
                                    <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank"><img
                                        src={google_icon} alt=""/></a>
                                </div>
                            </div>
                            <div className="d_flex justify_content_center align_items_center create_account_block">
                                <p className="fs_16 f_500">New Member?</p>
                                <div onClick={() => navigate("/register_as")} className="fs_16 f_500 c_blue">Create An
                                    Account
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}