import "./style.css";
import SidebarComp from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import favourites_page_pic from "../../assets/images/favourites_page_pic.png";
export default function Favourites(){
    return(
        <div className="d_flex">
            <div>
                <SidebarComp/>
            </div>
            <div className="main_dashboard">
                <Header isLogin={true}/>
                <div className="container_dashboard">
                    <div className="container_inner">
                        <div className="dashboard_b1 d_flex fd_column">
                            <div className="dashboard_b1_1 d_flex justify_content_space_between">
                                <p className="f_400 fs_36 c_black" style={{color:"#3C3C3C"}}>Favourites</p>
                            </div>
                            <div className="dashboard_b1_2 d_flex fd_column justify_content_center align_items_center margin_top_48">
                                <img src={favourites_page_pic} alt=""/>
                                <p className="fs_18 f_500 text_center">No Records Found.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}