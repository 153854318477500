import {useTimer} from "react-timer-hook";
import {useEffect} from "react";

export  default  function MyTimer({ expiryTimestamp, restartStatus }) {
    const {
        seconds,
        minutes,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    let restartTimer =() => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 1800);
        restart(time)
        console.log("restart time")
    }

    useEffect(() => {
        restartTimer()
    }, [restartStatus])

    return (
        <div style={{textAlign: 'center'}}>
            <div style={{fontSize: '16'}}>
                <span>{minutes.length<2 ? minutes+"0" : minutes}</span>:<span>{seconds.length<2 ? seconds.padEnd("1") : seconds}</span>
            </div>
        </div>
    );
}