import "./style.css";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import config from "../../config";
import Header from "../../components/header/Header";
import key_icon from "../../assets/images/key.svg";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";


export default function OneTimePassword(){
    let navigate = useNavigate();
    let [show, setShow] = useState(false)
    const [oneTimePass, setOneTimePass] = useState({
        code: "",
    })
    const [error, setError] = useState("")
    const [passBtn, setPassBtn] = useState(false)

    const handleChangePass = (e, name) => {
        setOneTimePass({...oneTimePass, [name]: e})
        setError("")
    }
    let showHidePass = () => {
        setShow(!show)
    }
    const oneTimeHandler = () => {
        let values={
            code:oneTimePass.code,
        }
        setPassBtn(true)
        console.log(values);
        if (oneTimePass.code.length ) {
            axios.post(`${config.url}/password/code/check`, values )
                    .then(response => {
                        console.log(response,"response");
                        localStorage.setItem('jwtToken',  response.data.token)
                        navigate("/login/reset_password",{state: {code: values.code}})
                    })
                    .catch(error => {
                        console.log(error.response,"error")
                        setError(error.response.data.message)
                    })
            }
    }

    return(
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="login_register_cont bc_white">
                            <h1 className="text_capitalize text_center fs_38 f_600">One Time Password</h1>
                            <div className="text_center fs_16 f_500 forget_pass_text">Fill One Time Password we have sent you via email.</div>
                            <div className="login_register_cont_block d_flex fd_column forget_password_cont_block">
                                <label htmlFor="" className="fs_16 f_500">One Time Password</label>
                                <input type={show ? "text" : "password"} placeholder=""
                                       style={!oneTimePass.code && passBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChangePass(e.target.value, "code")}
                                />
                                <img src={key_icon} alt="key_icon"/>
                                {show ? <AiOutlineEye className="hide_icon fs_16" onClick={showHidePass}/> :
                                    <AiOutlineEyeInvisible className="hide_icon fs_16" onClick={showHidePass}/>
                                }

                            </div>
                            <div className="d_flex fd_column justify_content_center align_items_center margin_top_48">
                                {error ? <p className="error">{error}</p> : null}
                                <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn" onClick={oneTimeHandler}>Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}
