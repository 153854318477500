import "./style.css";
import SidebarComp from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import tasks_page_pic from "../../assets/images/tasks_page_pic.png";
import payments_history_back_pic from "../../assets/images/payments_history_back_pic.png";

export default function PaymentsHistory(){
    return(
        <div className="d_flex">
            <div>
                <SidebarComp/>
            </div>
            <div className="main_dashboard">
                <Header isLogin={true}/>
                <div className="container_dashboard">
                    <div className="container_inner">
                        <div className="dashboard_b1 d_flex fd_column">
                            <div className="dashboard_b1_1 d_flex justify_content_space_between">
                                <p className="f_400 fs_36 c_black" style={{color:"#3C3C3C"}}>Payment History</p>
                            </div>
                            <div className="d_flex justify_content_space_between align_items_start filters_b">
                                <div className="d_flex fd_column filters_b_item">
                                    <label htmlFor="" className="fs_14 f_500 c_grey">Filter</label>
                                    <select name="" id="">
                                        <option value="">All</option>
                                    </select>
                                </div>
                                <div className="total_earned_b d_flex fd_column justify_content_start align_items_center">
                                    <label htmlFor="" className="fs_18 f_400">Total Earned</label>
                                    <div className="fs_24 f_500">N <span>0.00</span></div>
                                </div>
                            </div>
                            <div className="payment_history_b1_2 d_flex fd_column justify_content_center align_items_center margin_top_48">
                                <img src={payments_history_back_pic} alt=""/>
                                <div className="d_flex align_items_center payment_history_b1_2_btns">
                                    <button className="post_task_btn c_white fs_24 f_700">
                                        Post a Task
                                    </button>
                                    <button className="view_task_btn c_white fs_24 f_700">View Tasks</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}