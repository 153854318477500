import "./style.css";
import {useState} from "react";
import post_task_pic from "../../../../assets/images/post+_task_pic.svg";
import work_progress_pic from "../../../../assets/images/work_progres_pic.svg";
import task_completed_pic from "../../../../assets/images/Task_completed_pic.svg";

export default function HowWorkBlock1() {
    let [taskDone, setTaskDone]=useState([
        {
            id:1,
            img:post_task_pic,
            title:"Post your Task and choose an Expert",
            desc: "You have a task, we have the perfect Artisan  to help you out.",
        },
        {
            id:1,
            img:work_progress_pic,
            title:"Post your Task and choose an Expert",
            desc: "You have a task, we have the perfect Artisan  to help you out.",
        },
        {
            id:1,
            img:task_completed_pic,
            title:"Post your Task and choose an Expert",
            desc: "You have a task, we have the perfect Artisan  to help you out.",
        },
    ])
    return (
        <div className="bc_blue  HowWorkBlock1_cont">
            <div className="container">
                <div className="container_inner">
                    <h1 className="c_white fs_38 text_center text_capitalize">The best Place where people and businesses
                        get there task done</h1>
                    <div className="d_flex justify_content_center align_items_center margin_top_48">
                        <button className="how_works_btn bc_green fs_18 f_500 c_white">See How it works</button>
                    </div>

                    <div className="HowWorkBlock1_cont_block1  d_grid grid_columns_3fr margin_top_48">
                        {
                            taskDone.map((item,index)=>{
                                return(
                                    <div className="HowWorkBlock1_cont_block1_block bc_white d_flex fd_column justify_content_center align_items_center" key={item.id}>
                                        <img src={item.img} alt="img"/>
                                        <h3 className="fs_24 f_500 text_center">{item.title}</h3>
                                        <div className="fs_16 f_500 text_center">{item.desc}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="HowWorkBlock1_cont_block2 d_flex justify_content_space_between align_items_center">
                        <p className="fs_24 f_500 c_white text_capitalize">Post your Task</p>
                        <p className="fs_24 f_500 c_white text_capitalize">24/7 Customer support</p>
                        <p className="fs_24 f_500 c_white text_capitalize">Ratings and Reviews</p>
                        <p className="fs_24 f_500 c_white text_capitalize">Communications</p>
                        <p className="fs_24 f_500 c_white text_capitalize">Payment Method</p>
                    </div>
                </div>
            </div>

        </div>
    )
}