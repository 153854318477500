import "./style.css";
import {useState} from "react";
import pic1 from "../../../../assets/images/Maryam_pic.svg";
import pic2 from "../../../../assets/images/Posh_pic.svg";
import pic3 from "../../../../assets/images/Joan_pic.svg";
import star_pic from "../../../../assets/images/star_pic.svg";

export default function ClientsLove(){
  const [clientOpinion, setClientOpinion]=useState([
      {
          id:1,
          img:pic1,
          author: "Abiola Maryam",
          position:"Fashion designer",
          opinion: "Evriwork is the best platform to support service providers like me. My business has expanded to a whole new level through the platform",
          starsCount: "5",
          starPic: star_pic,
      },
      {
          id:2,
          img:pic2,
          author: "Joseph Posh",
          position:"internal Auditor",
          opinion: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vilDu kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil",
          starsCount: "5",
          starPic: star_pic,
      },
      {
          id:3,
          img:pic3,
          author: "Miss Joan",
          position:"IT Support",
          opinion: "Du kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vilDu kan vara ddrabbad. drabbad drabbad drabbadrabbaddrabbad. Dick vil",
          starsCount: "5",
          starPic: star_pic,
      },
  ])
    return(
        <div className="container">
            <div className="container_inner">
                <div className="clients_love_cont">
                    <h1 className="fs_38 c_blue d_flex align_items_center justify_content_center">Our Clients Love <span className="c_green fs_60">&#10084;</span></h1>
                    <div className="clients_love_cont_blocks">
                        <div className="clients_love_cont_block1">

                        </div>
                        <div className="clients_love_cont_block2 d_grid grid_columns_3fr grid_gab_35">
                            {clientOpinion.map((item,index)=>{
                                return(
                                    <div className="clients_love_cont_block2_item d_flex fd_column justify_content_center align_items_center" key={item.id}>
                                        <img src={item.img} alt="client_pic" className="client_pic"/>
                                        <h2 className="fs_28 f_600 c_black">{item.author}</h2>
                                        <span className="fs_18 f_500">{item.position}</span>
                                        <div className="text_center fs_16 f_500 text_capitalize">{item.opinion}</div>
                                        <div className="stars_block">
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                            <img src={item.starPic} alt=""/>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}