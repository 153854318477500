import "./style.css"
import SidebarComp from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import messages_page_pic from "../../assets/images/messages_page_pic.svg";

export default function Messages(){
    return (
        <div className="d_flex">
            <div>
                <SidebarComp/>
            </div>
            <div className="main_dashboard">
                <Header isLogin={true}/>
                <div className="container_dashboard">
                    <div className="container_inner">
                        <div className="dashboard_b1 d_flex fd_column">
                            <div className="dashboard_b1_1 d_flex justify_content_space_between">
                                <p className="f_400 fs_36 c_black" style={{color:"#3C3C3C"}}>Messages</p>
                                <div className="d_flex align_items_center">
                                    <button className="dash_post_btn c_white f_500 fs_16">Post A Task</button>
                                    <button className="view_task_btn c_white fs_16 f_500">View Task</button>
                                </div>
                            </div>
                            <div className="d_flex justify_content_start align_items_start filters_b">
                                <div className="d_flex fd_column filters_b_item">
                                    <label htmlFor="" className="fs_14 f_500 c_grey">Filter by Status</label>
                                    <select name="" id="">
                                        <option value="">All</option>
                                    </select>
                                </div>
                                <div className="d_flex fd_column filters_b_item">
                                    <label htmlFor="" className="fs_14 f_500 c_grey">filter by date</label>
                                    <select name="" id="">
                                        <option value="">Today</option>
                                    </select>
                                </div>
                            </div>
                            <div className="dashboard_b1_2 d_flex fd_column justify_content_center align_items_center margin_top_48">
                                <img src={messages_page_pic} alt=""/>
                                <p className="fs_18 f_500 text_center">You haven’t got any messages yet. Assign a task or get assigned to chat.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}