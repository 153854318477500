import "./style.css";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export default function TaskSummary() {
    let [task, setTask] = useState([
        {
            id: 1,
            number: 1,
            summary: "Job Offers",
        },
        {
            id: 2,
            number: 1,
            summary: "Assigned job",
        },
        {
            id: 3,
            number: 1,
            summary: "Awaiting Payment",
        },
        {
            id: 4,
            number: 1,
            summary: "Completed",
        },
    ])
    return (
        <div className="dashboard_b2">
            <p className="fs_36 f_400_c_black">My Task Summary</p>
            <div className="dashboard_b2_b d_grid grid_columns_4fr">
                {task.map((item, index) => {
                    return (
                        <div className="dashboard_b2_b_item bc_white d_flex fd_column" key={item.id}>
                            <span className="fs_32 f_500">{item.number}</span>
                            <p className="f_400 fs_18 LibreBaskerville">{item.summary}</p>
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}