import "./style.css"
import logo from "../../assets/images/logo.svg";
import {NavLink, useNavigate} from "react-router-dom";
import dashboard_icon from "../../assets/images/dashboard_icon_black.svg";
import arrow_left_icon from "../../assets/images/arrow_left_black.svg";
import arrow_down_blue from "../../assets/images/arrow_down_blue.svg";
import {useState} from "react";

export default function SidebarComp(){
    let [show,setShow]=useState(false)
    let navigate=useNavigate();
    let token = localStorage.getItem('jwtToken')

    let LogoutUser = () =>{
        localStorage.removeItem('jwtToken')
        localStorage.removeItem('token')
        navigate("/login")
        // axios.get(`${config.url}/auth/logout`, {
        //     headers: {
        //         'authorization': `${token}`
        //     }
        // })
        //     .then(response => {
        //         console.log(response, "logout");
        //         localStorage.removeItem("refreshToken")
        //         localStorage.removeItem("jwtToken")
        //         localStorage.removeItem("token")
        //         navigate("/login")
        //     })
        //     .catch(error => {
        //         console.log(error.response, "elq")
        //     })
    }

    return(
        <>
            {
                !show ?
                    <div className="sidebar d_flex fd_column" id="mySidebar">
                        <div onClick={() => navigate("/dashboard")}>
                            <img src={logo} alt="" className="logo"/>
                        </div>
                        {/*<button className="w3-bar-item w3-button w3-large w3-hide-large">Close &times;</button>*/}
                        <div className="navLinks_block margin_top_48 d_flex fd_column justify_content_space_between">
                            <div className="d_flex fd_column">
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active d_flex align_items_center 1c_blue fs_18 f_500' : 'sidebar_navLink 1 fs_18 f_500 c_black'}
                                          to="/dashboard">Dashboard</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active d_flex align_items_center 2 c_blue fs_18 f_500' : 'sidebar_navLink  d_flex align_items_center 2 fs_18 f_500 c_black'}
                                          to="/messages">Messages</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/tasks">Tasks</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/favourites">Favorites</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/payment_history">Payments history</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/payment_method">Payments method</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/refer_friend">Refer a friend</NavLink>
                                <div className="sidebar_navLink_settings_b d_flex justify_content_space_between align_items_center" onClick={()=>setShow(true)}>
                                    <div className="sidebar_navLink_settings d_flex align_items_center  fs_18 f_500 c_black">Setting</div>
                                    <img src={arrow_left_icon} alt="arrow" className="arrow_right_icon_black"/>
                                </div>
                                {/*<NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active 8 c_blue fs_18 f_500' : 'sidebar_navLink sidebar_navLink_settings  8 fs_18 f_500 c_black'}*/}
                                {/*          to="/settings">Settings</NavLink>*/}
                            </div>
                            <div className="d_flex fd_column navLink_b2 ">
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink fs_18 f_500 c_black'}
                                          to="/support">Help & Support</NavLink>
                                <button className="sidebar_navLink fs_18 f_500 c_black d_flex align_items_center" onClick={LogoutUser}>Log out</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="sidebar d_flex fd_column" id="mySidebar2">
                        <div onClick={() => navigate("/dashboard")}>
                            <img src={logo} alt="" className="logo"/>
                        </div>
                        {/*<button className="w3-bar-item w3-button w3-large w3-hide-large">Close &times;</button>*/}
                        <div className="navLinks_block margin_top_48 d_flex fd_column justify_content_space_between">
                            <div className="d_flex fd_column">
                                <div className="sidebar_navLink_settings_b d_flex justify_content_space_between align_items_center">
                                    <div className="sidebar_navLink_settings d_flex align_items_center  fs_18 f_500 c_black">Setting</div>
                                    <img src={arrow_down_blue} alt="arrow" className="arrow_right_icon_black" onClick={()=>setShow(true)}/>
                                </div>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active d_flex align_items_center 1c_blue fs_18 f_500' : 'sidebar_navLink 1 fs_18 f_500 c_black'}
                                          to="/account">Account</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active d_flex align_items_center 2 c_blue fs_18 f_500' : 'sidebar_navLink  d_flex align_items_center 2 fs_18 f_500 c_black'}
                                          to="/skills">Skills</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/reviews">Reviews</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/notification_settings">Notification Settings</NavLink>
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink  fs_18 f_500 c_black'}
                                          to="/portfolio">Portfolio</NavLink>
                            </div>
                            <div className="d_flex fd_column navLink_b2 ">
                                <NavLink  className={({isActive}) => isActive ? 'sidebar_navLink_active  c_blue fs_18 f_500' : 'sidebar_navLink fs_18 f_500 c_black'}
                                          to="/support">Help & Support</NavLink>
                                <button className="sidebar_navLink fs_18 f_500 c_black d_flex align_items_center" onClick={LogoutUser}>Log out</button>
                            </div>
                        </div>
                    </div>

        }

        </>
    )
}
