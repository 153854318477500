import "./style.css";
import {useState} from "react";
import transparency_icon from "../../../../assets/images/transparency_icon.svg";
import Security_icon from "../../../../assets/images/core_security_icon.svg";
import expertise_icon from "../../../../assets/images/expertise_icon.svg";


export default function CoreValues() {
    let [coreValue,setCoreValue]=useState([
        {
            id:1,
            img:transparency_icon,
            title:"Transparency",
            desc: "Our platform is based on transparency. Our customers and service providers are reliable.",
        },
        {
            id:2,
            img:Security_icon,
            title:"Security",
            desc: "Our customers and service providers can use our platform with confidence. we have a high security policy.",
        },
        {
            id:3,
            img:expertise_icon,
            title:"Expertise",
            desc: "We offer trusted, relaxed, verified and competent service providers",
        },
    ])
    return (
        <div className="core_values_cont margin_bottom_96">
            <h1 className="fs_38 f_600 c_blue text_center">Our Core Values</h1>
            <div className="core_values_cont_blocks d_grid grid_columns_3fr">
                {coreValue.map((item,index)=>{
                    return(
                        <div className="core_values_cont_block bc_white d_flex fd_column justify_content_center align_items_center" key={item.id}>
                            <img src={item.img} alt="icon"/>
                            <h3 className="fs_24 f_500 c_blue">{item.title}</h3>
                            <div className="fs_18 f_500 c_grey text_center">{item.desc}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}