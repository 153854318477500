import "./style.css";
import React, {useEffect, useState} from "react";
import DragDropFile from "./components/dragn_drop/DragDropFile";
import LoadingModal from "../loading_modal/LoadingModal";



export default function ProfilePicture(props) {
    let [error, setError] = useState("")
    const [modalIsOpen, setIsOpen] = useState(false);
    const [fileUpload, setFileUpload] = useState(false)

    function openModal() {
        setIsOpen(true);
    }


    let ImageUploaded =()=>{
        if(fileUpload){
            openModal()
        }
    }


    return (
        <div className="container">
            <div className="container_inner">
                <div className="register_details_cont bc_white">
                    <div className="identification_cont">
                        <div
                            className="d_flex fd_column justify_content_center align_items_center upload_img_block">
                            <h2 className="fs_38 f_600 c_blue text_center">Upload Image</h2>
                            <p className="text_center fs_16">Max file size is 2MB, Minimum dimension: 270x210 And
                                Suitable files are .jpg & .png</p>
                        </div>
                        <div className="upload_img_img_b">
                            <p className="fs_18 f_500 file_upload_title">File Upload</p>
                                <DragDropFile fileUpload={fileUpload} setFileUpload={setFileUpload}/>
                        </div>

                        <div className="d_flex justify_content_center align_items_center">
                            <div className="d_flex justify_content_space_between align_items_center">

                            </div>
                        </div>


                        <div className="d_flex  fd_column justify_content_center align_items_center">
                            {error ? <p className="error">{error}</p> : null}
                            <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn"
                                    onClick={ImageUploaded}>Next
                            </button>
                            <LoadingModal openModal={openModal}  modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} fileUpload={fileUpload}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}