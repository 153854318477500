import "./style.css"
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import HowWorkBlock1 from "./components/how_work_block1/HowWorkBlock1";
import chat_icon from "../../assets/images/chat_icon.svg";
import HowWorkBlock3 from "./components/how_work_block3/HowWorkBlock3";
import RatingReviews from "./components/ratings_reviews/RatingReviews";
import EvriworkPayment from "./components/evriwork_payment/EvriworkPayment";

export default function HowWorks() {
    return (
        <div className="main">
            <div>
                <Header/>
                <HowWorkBlock1 isLogin={true}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="d_flex justify_content_end HowWorkBlock2_chat_block">
                            <img src={chat_icon} alt="chat_icon" className=""/>
                        </div>
                        <HowWorkBlock3/>
                    </div>
                </div>
                <RatingReviews/>
                <EvriworkPayment/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}