import "../Home/style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import home_general_pic from "../../assets/images/landing_page_gen_pic.svg";
import home_general_pic_fon from "../../assets/images/landing_page_gen_pic_fone.svg";
import chat_icon from "../../assets/images/chat_icon.svg";
import VButton from "../../cors/button/V_button";
import FeelSecure from "./components/feel_secure/FeelSecure";
import PeopleChoose from "./components/peoople_choose/PeopleChoose";
import GetWork from "./components/get_work/GetWork";
import ExploreServices from "./components/explore_services/ExploreServices";
import ClientsLove from "./components/clients_love/ClientsLove";
import subscribe_pic from "../../assets/images/subscripe_pic.svg";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Subscribe from "./components/subscribe/Subscribe";



export default function Home() {
    let navigate=useNavigate()
    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="home_cont_b1 margin_bottom_96">
                    <img src={home_general_pic} alt="home_general_pic" className="home_general_pic"/>
                    <img src={home_general_pic_fon} alt="home_general_pic_fon" className="home_general_pic_fon"/>
                    <div className="home_cont_b1_text_block text_center d_flex fd_column justify_content_center align_items_center">
                        <h1 className="c_white f_700 fs_60">Post, Connect and Get jobs done.</h1>
                        <div className="c_lightGrey f_400 fs_18">Looking for a safe and easy way to connect with skilled providers? Looks no further than
                            our platform. With just clicks, you can get started today and get the job done.
                        </div>
                        <button className="post_task_btn bc_blue c_white fs_16 f_500" onClick={()=>navigate('/register_as')}>Post A Task</button>
                    </div>
                    <div>
                        <img src={chat_icon} alt="chat_icon" className="chat_icon"/>
                    </div>
                </div>
                <FeelSecure/>
                <PeopleChoose/>
                <GetWork/>
                <ExploreServices/>
                <ClientsLove/>
                <Subscribe/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}