import "./style.css";
import Header from "../../components/header/Header";

import {useLocation} from "react-router-dom";

export default function VerifyEmail(){
    const {state}= useLocation();


    return (
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="verify_email_cont bc_white d_flex fd_column justify_content_center align_items_center">
                            <h1 className="text_capitalize text_center fs_38 f_600">Verify your Email</h1>
                         <div className="text_center fs_16 f_500 margin_top_48">A verification mail has been sent to you </div>
                            <span className="fs_16 f_500">{state?.email}</span>
                            <div className="fs_16 f_500 c_blue resend_mail">Didn’t get the mail ? Resend it again</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}