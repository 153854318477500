import React, {useEffect, useState} from "react";
import {Line} from "rc-progress";
import Upload from "rc-upload";
import "./style.css";
import ok_icon from "../../../../../assets/images/ok_icon.svg";
import {useNavigate} from "react-router-dom";
import upload_img_icon from "../../../../../assets/images/upload_img_icon.svg";
import delete_icon from "../../../../../assets/images/delete_icon.png";
import edit_icon from "../../../../../assets/images/edit-icon.png";

const fileTypes = ["JPEG", "PNG", "JPG"];

export default function ImageUpload({changeStep, fileOut,fileUpload, setFileUpload}) {
    const [stepUpload, setStepUpload] = useState(1);
    const navigate = useNavigate();
    const [percentage, setPercentage] = useState(0);
    const [imgData, setImgData] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState();
    const [disabled, setDisabled] = useState(false);
    const [lineFinisShow, setLineFinisShow] = useState(false);
    const [file, setFile] = useState(null);

    const handleChange = (file) => {
        setFile(file);
        fileName(file);
        setFileUpload(!fileUpload)
    };

    useEffect(() => {
        if (fileOut) {
            handleBeforeUploadOut(fileOut)
        }
    }, [fileOut])
    console.log(imgData, 'ert');

    const handleBeforeUpload = (file) => {
        setFileName(file.name);
        setDisabled(true);
        if (file.type === "image/png") {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImgData(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBeforeUploadOut = (file) => {
        setFileName(file.name);
        setStepUpload(2);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImgData(reader.result);
        };
        reader.readAsDataURL(file);
        setIsUploading(true);
        setLineFinisShow(false);
        setPercentage(100);

    };

    const handleProgress = (step) => {
        if (stepUpload !== 2) {
            setStepUpload(2);
        }
        setPercentage(Math.round(step.percent));
    };

    const handleSuccess = (e) => {
        setIsUploading(true);
        setFileUpload(!fileUpload)
    };

    useEffect(() => {
        let time;
        if (isUploading) {
            time = setTimeout(() => {
                setLineFinisShow(true);
                setShow(true);
            }, 2000);
        }
        return () => clearTimeout(time);
    }, [isUploading]);

    const handleEdit = () => {
        setDisabled(false);
    };

    const handleDelete = () => {
        setDisabled(false);
        setPercentage(0);
        setStepUpload(1);
        setIsUploading(false);
        setLineFinisShow(false);
    };

    const handleCancel = () => {
        setDisabled(false);
        setPercentage(0);
        setStepUpload(1);
        setFileName(null);
    };

    const renderUpload = () => {
        switch (stepUpload) {
            case 1:
                return (
                    <>
                        <button
                            id="upload-button"
                            className="btn-file upload_img_btn c_white fs_16 f_500"
                        >
                            Upload Image
                        </button>
                    </>
                );
            case 2:
                return (
                    <React.Fragment>
                        <div className="d_flex justify_content_space_between align_items_center react_fragment_div">
                            {imgData && (
                                <div className="img_b d_flex align_items_center justify_content_center">
                                    {imgData ? (
                                        <img src={imgData} alt="uploaded" width="50"/>
                                    ) : (
                                        <img
                                            src={upload_img_icon}
                                            alt="uploaded"
                                            width="50"
                                            height="50"
                                        />
                                    )}
                                </div>
                            )}
                            <div className="upload-list">
                                <div className="file-name">
                                    <b className="fs_16 f_500 file-name_p">{fileName.length<=15 ? fileName : (fileName.slice(0,15)+"..." +fileName.slice(fileName.length-4))}</b>
                                </div>
                                <div className="progress-container">
                                    {lineFinisShow ? null : (
                                        <Line
                                            percent={percentage}
                                            strokeWidth={9}
                                            trailWidth={9}
                                            trailColor="#FFF"
                                            strokeColor={!isUploading ? "#DCE0FF" : "#191970"}
                                        />
                                    )}
                                    <div className="progress-text">
                                        {!isUploading ? (
                                            <div className="d_flex align_items_center justify_content_space_between">
                                                <p>Uploading</p>
                                                <div>{`${percentage}%`}</div>
                                            </div>
                                        ) : lineFinisShow ? null : (
                                            `Finished`
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isUploading ? (
                                show ? (
                                    <div className="upload_ok_icon fs_16 f_500">
                                        <div className="d_flex align_items_center">
                                            <button onClick={handleEdit}>
                                                <img src={edit_icon} alt="edit_icon" className="edit_icon"/>
                                            </button>
                                            <button onClick={handleDelete}>
                                                <img
                                                    src={delete_icon}
                                                    alt="delete_icon"
                                                    className="delete_icon"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="upload_ok_icon fs_16 f_500"
                                        onClick={() => {
                                            setPercentage(0);
                                        }}
                                    >
                                        <img src={ok_icon} alt="ok_icon"/>
                                    </div>
                                )
                            ) : (
                                <div
                                    className="upload_ok_icon fs_16 f_500"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                );
            default:
                return null;
        }
    };

    return (
        <div
            className="d_flex justify_content_center align_items_center"
            style={{width: "100%"}}
        >
            <Upload
                action="https://httpbin.org/post"
                disabled={disabled}
                accept=".png, .pdf, .txt"
                beforeUpload={handleBeforeUpload}
                onProgress={handleProgress}
                onStart={() => {
                    setIsUploading(false)
                    setPercentage(0)
                    setLineFinisShow(false)
                }}
                onSuccess={handleSuccess}
                onError={(err) => {
                    console.log("onError", err);
                }}
            >
                {renderUpload()}
            </Upload>
        </div>
    );
}
