import "./style.css";
import dropDawn_arrow_icon from "../../../../assets/images/arrow_down_grey.svg";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";



export default function PersonalInformation(props){
    let navigate=useNavigate()
    let [error, setError]=useState('')
    let [showLocation, setShowLocation]=useState(false)
    let [locationState, setLocationState]=useState([
        {
            id:1,
            status:true,
            location: "USA",
        },
        {
            id:2,
            status:false,
            location: "Germany",
        },
    ])
    let [showSkills, setShowSkills]=useState(false)
    let [skillsState, setSkillsState]=useState([
        {
            id:1,
            status:true,
            skill: "designer, electrician",
        },
        {
            id:2,
            status:false,
            skill: "Marketing",
        },
    ])
    let closeLocationRef=useRef()
    let chooseLocationRef=useRef()
    let closeSkillsRef=useRef()
    let chooseSkillsRef=useRef()
    const handleClickLocationDropDawn =()=>{
        setShowLocation(!showLocation)
    }
    const handleClickSkillsDropDawn =()=>{
        setShowSkills(!showSkills)
    }
    const handleClickOutside = (e) => {
        let locationRef = chooseLocationRef;
        let skillRef = closeSkillsRef;
        let skillsRef = chooseSkillsRef;
        if (locationRef.current && !locationRef.current.contains(e.target) && !closeLocationRef.current.contains(e.target)) {
            setShowLocation(false);
        }
        if (skillsRef.current && !skillsRef.current.contains(e.target) && !closeSkillsRef.current.contains(e.target)) {
            setShowSkills(false);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);
    let changeLocation = (index) => {
        let arr = locationState
        arr.map(data => {
            data.status = false
        })
        arr[index].status = true
        setShowLocation(false)
        setLocationState([...arr])
    }
    let changeSkill = (index) => {
        let arr = skillsState
        arr.map(data => {
            data.status = false
        })
        arr[index].status = true
        setShowSkills(false)
        setSkillsState([...arr])
    }

    return(
        <div className="container">
            <div className="container_inner">
                <div className="register_details_cont bc_white">
                    <div className="d_flex fd_column">
                        <div className="register_details_cont_block d_flex fd_column">
                            <label htmlFor="" className="fs_16 f_500">Full Name</label>
                            <input type="text" placeholder="First name and Lastname"
                            />
                        </div>
                        <div className="register_details_cont_block d_flex fd_column">
                            <label htmlFor="" className="fs_16 f_500">Email Address</label>
                            <input type="email" placeholder=""/>
                        </div>
                        <div className="register_details_cont_block d_flex fd_column">
                            <label htmlFor="" className="fs_16 f_500">Location</label>
                            <div className="dropdown">
                                <div className="dropBtn select_block d_flex justify_content_space_between align_items_center" onClick={handleClickLocationDropDawn} ref={closeLocationRef}>
                                    <div>
                                        {
                                            locationState.map((item, index) => {
                                                if (item.status) {
                                                    return(
                                                        <div key={item.id} className="dropdown_item text_capitalize fs_16 f_500" >{item.location}</div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    {
                                        !showLocation ?
                                            <img src={dropDawn_arrow_icon} alt="dropDawn_arrow_icon" className="dropDawn_arrow_bottom"/>
                                            :
                                            <img src={dropDawn_arrow_icon} alt="dropDawn_arrow_icon" className="dropDawn_arrow_top"/>
                                    }
                                </div>
                                {
                                    showLocation ?
                                        <div className="dropdown_container" ref={chooseLocationRef}>
                                            <div className="dropdown_inner">
                                                {
                                                    locationState.map((item, index) => {
                                                        return(
                                                            <div key={item.id} className="dropdown_item text_capitalize fs_16 f_500" onClick={()=>changeLocation(index)} >{item.location}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="register_details_cont_block d_flex fd_column">
                            <label htmlFor="" className="fs_16 f_500">Address</label>
                            <input type="text" placeholder="Enter Address"/>
                        </div>
                        <div className="register_details_cont_block d_flex fd_column">
                            <label htmlFor="" className="fs_16 f_500">Skills</label>
                            <div className="dropdown">
                                <div className="dropBtn select_block d_flex justify_content_space_between align_items_center" onClick={handleClickSkillsDropDawn} ref={closeSkillsRef}>
                                    <div>
                                        {
                                            skillsState.map((item, index) => {
                                                if (item.status) {
                                                    return(
                                                        <div key={item.id} className="dropdown_item text_capitalize fs_16 f_500" onClick={()=>changeSkill(item.id)}>{item.skill}</div>
                                                    )

                                                }
                                            })
                                        }
                                    </div>
                                    {
                                        !showSkills ?
                                            <img src={dropDawn_arrow_icon} alt="dropDawn_arrow_icon" className="dropDawn_arrow_bottom"/>
                                            :
                                            <img src={dropDawn_arrow_icon} alt="dropDawn_arrow_icon" className="dropDawn_arrow_top"/>
                                    }
                                </div>
                                {
                                    showSkills ?
                                        <div className="dropdown_container" ref={chooseSkillsRef}>
                                            <div className="dropdown_inner">
                                                {
                                                    skillsState.map((item, index) => {
                                                        return(
                                                            <div key={item.id} className="dropdown_item text_capitalize fs_16 f_500" onClick={()=>changeSkill(index)}>{item.skill}</div>
                                                        )

                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="register_details_cont_block d_flex fd_column">
                            <label htmlFor="" className="fs_16 f_500 text_capitalize">Brief description of your skill (s)</label>
                            <textarea name="" id="" cols="30" rows="10">Tell Us About Your Skill (S)</textarea>
                        </div>
                    </div>
                    <div className="d_flex  fd_column justify_content_center align_items_center margin_top_48">
                        {error ? <p className="error">{error}</p> : null}
                        <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn"  onClick={() => props.changeStep('next')}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}