// import "./style.css";
import Modal from 'react-modal';
import React, {useEffect} from "react";
import Loading from "../loading/Loading";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:"100%",
        height:"100vh",
        zIndex:"999",
        background: "rgba(0, 0, 0, 0.7)"
    },
};

export default function LoadingModal(props){

    // useEffect(() => {
    //     console.log("timer worked")
    //     const timer = setTimeout(() => navigate("/register_provider/account_verification"), 5000);
    //     return () => clearTimeout(timer);
    // }, [props.fileUpload]);


    return(
            <Modal
                isOpen={props.modalIsOpen}
                style={customStyles}
                ariaHideApp={false}
            >
                <Loading/>
            </Modal>
    )
}
