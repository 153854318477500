import "./style.css"
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import address_icon from "../../assets/images/address_icon.svg";
import tel_icon from "../../assets/images/tel_icon.svg";
import email_icon from "../../assets/images/email_icon.svg";
import user_icon from "../../assets/images/user.svg";
import ContactUsArea from "./contact_us_area/ContactUsArea";
import {useState} from "react";
import axios from "axios";
import config from "../../config";


export default function ContactUs() {
    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="contact_us_cont">
                            <h1 className="text_center fs_60 f_700">Contact Us</h1>
                            <div className="contact_us_desc fs_18 f_400 text_center margin_bottom_96">
                                Got a special request, need more information about the websitre or you simply have
                                something to talk abput? Reach out to us and we will respond as soon as possible
                            </div>
                            <div className="contact_us_info d_flex justify_content_space_between align_items_center">
                                <a className="d_flex fd_column justify_content_center align_items_center text_center contact_us_info_block">
                                    <img src={address_icon} alt="address_icon" className="address_icon"/>
                                    <p className="text_capitalize text_center fs_18 f_500">plot 6, magodo estate,
                                        lagos</p>
                                </a>
                                <a href="tel:+23457240000"
                                   className="d_flex fd_column justify_content_center align_items_center text_center contact_us_info_block">
                                    <img src={tel_icon} alt="tel_icon" className="tel_icon"/>
                                    <p className="text_capitalize text_center fs_18 f_500">+23457240000</p>
                                </a>
                                <a href="mailto:customersupport@evriwork.org"
                                   className="d_flex fd_column justify_content_center align_items_center text_center contact_us_info_block">
                                    <img src={email_icon} alt="email_icon" className="email_icon"/>
                                    <p className="text_capitalize text_center fs_18 f_500">customersupport@evriwork.org</p>
                                </a>
                            </div>
                           <ContactUsArea/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}