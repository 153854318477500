import "./style.css"
import {useEffect, useRef, useState} from "react";
import arrow_select_down from "../../../../assets/images/arrow_select_down.png";
import arrow_select_up from "../../../../assets/images/arrow_select_up.png";

export default function VSelectDate(props){
    let [selectDropDawn, setSelectDropDawn] = useState(false)
    let [selectDate, setSelectDate] = useState([
        {
            id: 1,
            date: "Today"
        },
        {
            id: 2,
            date: "Last Month"
        },
    ])
    const selectDateRef = useRef(null)
    const closeSelectDropDawnRef = useRef(null);

    /*    useEffect(() => {
            if (props.status) {
                setSelectStatus([...props.status])
            }
        }, [props])*/

    let showSelect = () => {
        setSelectDropDawn(!selectDropDawn)
    }
    const handleClickOutSide = (e) => {
        let closeDropDawnRef = closeSelectDropDawnRef;
        let dateRef = selectDateRef;
        if (dateRef.current && !dateRef.current.contains(e.target) && !closeDropDawnRef.current.contains(e.target)) {
            setSelectDropDawn(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    return (
        <>
            <div className="select_cont d_flex align_items_center justify_content_space_between" onClick={showSelect} ref={closeSelectDropDawnRef}>
                <div id="" className="vSelect fs-16 f-400" > {props.dateState} </div>
                {
                    !selectDropDawn ?
                        <img src={arrow_select_down} alt="" className="select_dropdown_arrow"/>
                        :
                        <img src={arrow_select_up} alt="" className="select_dropdown_arrow"/>
                }
            </div>
            {
                selectDropDawn ?
                    <div className="select_item_cont" ref={selectDateRef}>
                        {selectDate.map((item, index) => {
                            return (
                                <div className="select_item d-flex" key={index} onClick={() => {
                                    props.setDateState(item.date)
                                }}>
                                    {/*<input type="radio" id="" name="radioBtn" className="select_item_checkbox"*/}
                                    {/*       onChange={() => {*/}
                                    {/*           props.setStatusState(item.country)*/}
                                    {/*           props.changeFilterData({status: [item.id]})*/}
                                    {/*       }}/>*/}
                                    {item.date}
                                </div>
                            )
                        })
                        }
                    </div>
                    : null
            }
        </>
    )
}