import "./style.css";
import footer_logo from "../../assets/images/footer_logo.svg";
import facebook_icon from "../../assets/images/footer_facebook _icon.svg";
import twitter_icon from "../../assets/images/twitter_icon.svg";
import google_icon from "../../assets/images/footer_google_icon.svg";
import instagram_icon from "../../assets/images/instagram_icon.svg";
import {NavLink} from "react-router-dom";
import phone_icon from "../../assets/images/phone_icon.svg";
import address_icon from "../../assets/images/footer_address_icon.svg";
import email_icon from "../../assets/images/footer_email_icon.svg";

export default function Footer() {
    return (
        <div className="footer_cont bc_darkBlue">
            <div className="container">
                <div className="container_inner">
                    <div className="footer_cont_blocks d_flex justify_content_space_between align_items_center">
                        <div className="footer_cont_block1 d_flex fd_column">
                            <img src={footer_logo} alt="footer_logo" className="footer_logo"/>
                            <div className="c_lightGrey fs_16 f_500">Du kan vara ddrabbad. drabbad drabbad
                                drabbadrabbaddrabbad. Dick vil
                            </div>
                            <div className="c_lightGrey fs_16 f_500">Design by More Techies CELL-1</div>
                            <div className="social_blocks d_flex">
                                <a href="" target="_blank"><img src={facebook_icon} alt="facebook_icon"/></a>
                                <a href="" target="_blank"><img src={twitter_icon} alt="twitter_icon"/></a>
                                <a href="" target="_blank"><img src={google_icon} alt="google_icon"/></a>
                                <a href="" target="_blank"><img src={instagram_icon} alt="instagram_icon"/></a>
                            </div>
                        </div>
                        <div className="footer_cont_block2 d_flex justify_content_space_between align_items_start">
                            <div className="d_flex fd_column footer_cont_block2_item">
                                <h3 className=" fs_24 f_500">Quick Links</h3>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/">Home</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/about">About Us</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">FAQs</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">Get Started</NavLink>
                            </div>
                            <div className="d_flex fd_column footer_cont_block2_item">
                                <h3 className="fs_24 f_500">About Us</h3>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">Mission</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">Vision</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">Terms & Policy</NavLink>
                            </div>
                            <div className="d_flex fd_column footer_cont_block2_item">
                                <h3 className="fs_24 f_500">Categories</h3>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">Clients</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">Skill Providers</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_16 f_700' : 'footer_navLink fs_16 f_500 c_lightGrey'}
                                    to="/*">Features</NavLink>
                            </div>
                            <div className="d_flex fd_column footer_cont_block2_item contact_block">
                                <h3 className=" fs_24 f_500">Contact Us</h3>
                                <a href="tel:(239) 555-0108" className="c_lightGrey fs_16 f_500 d_flex align_items_center"><img src={phone_icon}
                                                                                                      alt="phone_icon"/>(239)
                                    555-0108</a>
                                <a href="" className="c_lightGrey fs_16 f_500 d_flex align_items_center"><img src={address_icon}
                                                                                    alt="address_icon"/>7480 Mockingbird
                                    Hill undefined </a>
                                <a href="mailto:Email@moretechies.org.uk" target="_blank"
                                   className="c_lightGrey fs_16 f_500 d_flex align_items_center"><img src={email_icon} alt="email_icon"/>Email@moretechies.org.uk</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}