import "./style.css"
import Header from "../../components/header/Header";
import profile_icon from "../../assets/images/profile-circle.svg";
import email_icon from "../../assets/images/sms.svg";
import key_icon from "../../assets/images/key.svg";
import facebook_icon from "../../assets/images/facebook_icon.svg";
import google_icon from "../../assets/images/google_icon.svg";
import {useNavigate} from "react-router-dom";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {useState} from "react";
import config from "../../config";
import axios from "axios";

export default function RegisterClient() {
    let navigate = useNavigate();
    let [show, setShow] = useState(false)
    let [showConfirm, setShowConfirm] = useState(false)
    const [register, setRegister] = useState({
        name: "",
        email: "",
        password: "",
        c_password: "",
        policy: "",
        registrationRole:"1",
    })
    const [error, setError] = useState("")
    const [registerBtn, setRegisterBtn] = useState(false)
    let showHidePass = (e) => {
        setShow(!show)
    }
    let showHideConfirmPass = (e) => {
        setShowConfirm(!showConfirm)
    }
    const handleChangeRegister = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(register.email).toLowerCase());
    }
    let createAccount = () => {
        let values = {
            name: register.name,
            email: register.email,
            password: register.password,
            c_password: register.c_password,
            policy: register.policy,
            registrationRole: register.registrationRole,
        }
        console.log(values, "values")
        setRegisterBtn(true)
        if (register.name && register.email.length && register.email
            && register.password.length && register.c_password) {
            if (register.password.length > 7) {
                if (register.password === register.c_password) {
                    if (validateEmail()) {
                        console.log(values, '1111111111');
                        axios.post(`${config.url}/register`, values)
                            .then(response => {
                                console.log(response, "patasxan");
                                navigate("/register_client/verify_email", {state:{email: register.email } })
                            })
                            .catch(error => {
                                // console.log(error, 'err')
                                console.log(error.response.data.message, 'error response')
                                setError(error.response?.data?.message)
                            })
                    } else {
                        setError("Incorrect email address")
                    }
                } else {
                    setError("Passwords are different")
                }
            } else {
                setError("Password length is small")
            }
        }
    }

    return (
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="login_register_cont bc_white">
                            <h1 className="text_capitalize text_center fs_38 f_600">Sign up as Client</h1>
                            <div className="login_register_cont_blocks d_flex fd_column">
                                <div className="login_register_cont_block d_flex fd_column">
                                    <label htmlFor="" className="fs_16 f_500">Full Name</label>
                                    <input type="email" placeholder="First name and Lastname"
                                           style={!register.name && registerBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeRegister(e.target.value, "name")}
                                    />
                                    <img src={profile_icon} alt="email_icon"/>
                                </div>
                                <div className="login_register_cont_block d_flex fd_column">
                                    <label htmlFor="" className="fs_16 f_500">Email address/Mobile number</label>
                                    <input type="email" placeholder="Enter email address"
                                           style={!register.email && registerBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeRegister(e.target.value, "email")}
                                    />
                                    <img src={email_icon} alt="email_icon"/>
                                </div>
                                <div className="login_register_cont_block d_flex fd_column">
                                    <label htmlFor="" className="fs_16 f_500">Password</label>
                                    <input type={show ? "text" : "password"} placeholder="Password"
                                           style={!register.password && registerBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeRegister(e.target.value, "password")}
                                    />
                                    <img src={key_icon} alt="key_icon"/>
                                    {show ? <AiOutlineEye className="hide_icon fs_16" onClick={showHidePass}/> :
                                        <AiOutlineEyeInvisible className="hide_icon fs_16" onClick={showHidePass}/>
                                    }
                                </div>
                                <div className="login_register_cont_block d_flex fd_column">
                                    <label htmlFor="" className="fs_16 f_500">Confirm Password</label>
                                    <input type={showConfirm ? "text" : "password"} placeholder="Confirm Password"
                                           style={!register.c_password && registerBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeRegister(e.target.value, "c_password")}
                                    />
                                    <img src={key_icon} alt="key_icon"/>
                                    {showConfirm ?
                                        <AiOutlineEye className="hide_icon fs_16" onClick={showHideConfirmPass}/> :
                                        <AiOutlineEyeInvisible className="hide_icon fs_16"
                                                               onClick={showHideConfirmPass}/>
                                    }
                                </div>
                                <div className="d_flex remember_forget_pass_block">
                                    <div className="remember_me_block d_flex align_items_center">
                                        <input type="checkbox" checked/>
                                        <label htmlFor="" className="fs_14 f_500 d_flex c_black"
                                               style={!register.policy && registerBtn ? {color: "red"} : null}>I Agree
                                            to Evriwork <div className=''>User Agreement</div> and <div
                                                className="">Privacy Policy</div></label>
                                    </div>
                                </div>
                            </div>
                            <div className="d_flex  fd_column justify_content_center align_items_center margin_top_48">
                                {error ? <p className="error">{error}</p> : null}
                                <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn"
                                        onClick={createAccount}>Create account
                                </button>
                            </div>
                            <p className="margin_top_48 fs_24 f_500 text_center c_grey">Or</p>
                            <p className="text_center c_grey sign_in_using fs_18 f_500">Sign In using</p>
                            <div
                                className="d_flex justify_content_center align_items_center login_social_blocks margin_bottom_96">
                                <div>
                                    <a href="https://www.facebook.com" target="_blank"><img src={facebook_icon} alt=""/></a>
                                </div>
                                <div>
                                    <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank"><img
                                        src={google_icon} alt=""/></a>
                                </div>
                            </div>
                            <div className="d_flex justify_content_center align_items_center create_account_block">
                                <p className="fs_16 f_500"> Existing Member?</p>
                                <div onClick={() => navigate("/login")} className="fs_16 f_500 c_blue"> Sign in</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}