import React, {useState} from 'react';
import ReactLoading from 'react-loading';
import "./style.css";



const  Loading = ({ type, color }) => (
        <div className="d_flex justify_content_center align_items_center loading_block">
            <ReactLoading type={"spin"} color={color} height={'20%'} width={'20%'}/>
        </div>
)
export default Loading;


