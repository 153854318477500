import "./style.css";
import rating_pic1 from "../../../../assets/images/rating_pic1.svg";
import {useState} from "react";
import star_pic from "../../../../assets/images/star_pic.svg";

export default function RatingReviews(){
    let [rating, setRating]=useState([
        {
            id:1,
            img:rating_pic1,
            name: "Steve",
            position: "Project Manager",
            posted: 10,
            completed: 10,
            reviews: 8,
            starsCount: 5,
        },
        {
            id:2,
            img:rating_pic1,
            name: "Steve",
            position: "Project Manager",
            posted: 10,
            completed: 10,
            reviews: 8,
            starsCount: 5,
        },
        {
            id:3,
            img:rating_pic1,
            name: "Steve",
            position: "Project Manager",
            posted: 10,
            completed: 10,
            reviews: 8,
            starsCount: 5,
        },
    ])

    const giveClass = (parameter) => {
        switch (parameter) {
            case 0:
                return "rating_reviews_cont_item1 bc_white d_flex fd_column align_items_center justify_content_center";
            case 1:
                return  "rating_reviews_cont_item2 bc_white d_flex fd_column align_items_center justify_content_center";
            case 2:
                return "rating_reviews_cont_item3 bc_white d_flex fd_column align_items_center justify_content_center ";
            default:
                return "rating_reviews_cont_item1 bc_white d_flex fd_column align_items_center justify_content_center";
        }
    };
    return(
        <div className="rating_reviews_cont bc_purple margin_bottom_96">
            <div className="container">
                <div className="container_inner">
                    <h2 className="fs_38 f_600 c_blue text_center margin_bottom_96">Rating & Reviews</h2>
                    <div className="rating_reviews_cont_items d_flex justify_content_space_between margin_top_48">
                        {
                            rating.map((item,index)=>{
                                return(
                                    <div key={item.id}
                                           className={giveClass(item.id)}>
                                        <img src={item.img} alt="img"/>
                                        <p className="f_700 fs_20">{item.name}</p>
                                        <span className="fs_14 f_700 c_grey">{item.position}</span>
                                        <div className="d_flex justify_content_space_between align_items_center rating_reviews_cont_item_dates">
                                            <div className="d_flex fd_column align_items_center justify_content_center">
                                                <p className="f_700 fs_16">Posted</p>
                                                <span className="f_700 fs_14">{item.posted}</span>
                                            </div>
                                            <div  className="d_flex fd_column align_items_center justify_content_center">
                                                <p className="f_700 fs_16">Completed</p>
                                                <span className="f_700 fs_14">{item.completed}</span>
                                            </div>
                                            <div  className="d_flex fd_column align_items_center justify_content_center">
                                                <p className="f_700 fs_16">Reviews</p>
                                                <span className="f_700 fs_14">{item.reviews}</span>
                                            </div>
                                        </div>
                                        <div className="rating_reviews_cont_items_stars_block d_flex justify_content_space_between align_items_center">
                                            <img src={star_pic} alt="star_pic"/>
                                            <img src={star_pic} alt="star_pic"/>
                                            <img src={star_pic} alt="star_pic"/>
                                            <img src={star_pic} alt="star_pic"/>
                                            <img src={star_pic} alt="star_pic"/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
