import "./style.css";
import SidebarComp from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import payments_method_back_pic from "../../assets/images/payment_method_back_pic.svg";

export default function PaymentsMethod(){
    return(
        <div className="d_flex">
            <div>
                <SidebarComp/>
            </div>
            <div className="main_dashboard">
                <Header isLogin={true}/>
                <div className="container_dashboard">
                    <div className="container_inner">
                        <div className="dashboard_b1 d_flex fd_column">
                            <div className="dashboard_b1_1 d_flex justify_content_space_between">
                                <p className="f_400 fs_36 c_black" style={{color:"#3C3C3C"}}>Payment Method</p>
                            </div>
                            <div className="d_flex justify_content_space_between align_items_start filters_b">
                                <div className="d_flex fd_column filters_b_item">
                                    <label htmlFor="" className="fs_14 f_500 c_grey">Filter</label>
                                    <select name="" id="">
                                        <option value="">All</option>
                                    </select>
                                </div>
                            </div>
                            <div className="payment_history_b1_2 d_flex fd_column justify_content_center align_items_center margin_top_48">
                                <img src={payments_method_back_pic} alt=""/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}