import "./style.css";
import subscribe_pic from "../../../../assets/images/subscripe_pic.svg";
import {useRef, useState} from "react";
import axios from "axios";
import config from "../../../../config";

export default function Subscribe(){
    const ref = useRef(null);
    const [subscribe, setSubscribe] = useState({
        email: "",
    })
    const [error, setError] = useState("")


    const handleChangeSubscribe = (e, name) => {
        setSubscribe({...subscribe, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(subscribe.email).toLowerCase());
    }

    let SubscribeUser =() =>{
        let value={
            email: subscribe.email,
        }
        if (subscribe.email) {
                if (validateEmail()) {
                    axios.post(`${config.url}/subscribe/{email}`,value )
                        .then(response => {
                            console.log(response,"response");
                            console.log(response.data)
                            ref.current.value = "";
                        })
                        .catch(error => {
                            console.log(error.response,"error")
                            setError(error.response.data.message)
                        })
                } else {
                    setError("Incorrect email address")
                }
            } else {
                setError("Please fill email address")
            }
        }

    return(
        <div className="container">
            <div className="container_inner">
                <div className="subscribe_cont_block d_flex fd_column justify_content_center align_items_center margin_top_48">
                    <img src={subscribe_pic} alt="" />
                    <div className="text_capitalize fs_24 f_500 subscribe_cont_block_desc">
                        Suscribe to our newsletter to receive the latest updates and exclusive offers every week. No Spam
                    </div>
                    <div className="margin_top_48 d_flex subscribe_cont_block_input_b">
                        <input type="email" placeholder="Enter your email address" ref={ref}
                               onChange={(e) => handleChangeSubscribe(e.target.value, "email")}/>
                        <button className="subscribe_btn bc_purple fs_24 f_500 c_grey" onClick={SubscribeUser}>Subscribe</button>
                    </div>
                    {error ? <p className="error">{error}</p> : null}
                </div>
            </div>
        </div>
    )
}