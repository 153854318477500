import "./style.css";
import Header from "../../components/header/Header";
import SidebarComp from "../../components/sidebar/Sidebar";
import TaskSummary from "./components/task_summary/TaskSummary";

export default function Dashboard() {
    return (
        <div className="d_flex">
            <div>
                <SidebarComp/>
            </div>
            <div className="main_dashboard">
                <Header isLogin={true}/>
                <div className="container_dashboard">
                    <div className="container_inner">
                        <div className="dashboard_b1">
                            <p className="f_400 fs_36 c_black">Hello, Steve</p>
                        </div>
                        <TaskSummary/>
                    </div>
                </div>
            </div>
        </div>
    )
}