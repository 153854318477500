import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import Home from "../src/pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import HowWorks from "./pages/HowWorks/HowWorks";
import Services from "./pages/Services/Services";
import ContactUs from "./pages/ContactUs/ContactUs";
import Login from "./pages/Login/Login";
import RegisterClient from "./pages/RegisterClient/RegisterClient";
import Dashboard from "./dashboard_pages/Dashboard/Dashboard";
import RegisterAs from "./pages/RegisterAs/RegisterAs";
import RegisterProvider from "./pages/RegisterProvider/RegisterProvider";
import PersonalInfo from "./pages/PersonalInfo/PersonalInfo";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import OneTimePassword from "./pages/OneTimePassword/OneTimePassowrd";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import AccountVerification from "./pages/AccontVerification/AccountVerification";
import PrivateRoute from "./components/routing/PrivateRoute";
import Notifications from "./dashboard_pages/Notifications/Notifications";
import Messages from "./dashboard_pages/Messages/Messages";
import Tasks from "./dashboard_pages/Tasks/Tasks";
import Favourites from "./dashboard_pages/Favourites/Favourites";
import PaymentsHistory from "./dashboard_pages/Payments_History/PaymentsHistory";
import PaymentsMethod from "./dashboard_pages/Payment_Method/PaymentMethod";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/"  element={<Home />}/>
                <Route path="/register_as"  element={<RegisterAs />}/>
                <Route path="/about"  element={<AboutUs />}/>
                <Route path="/works"  element={<HowWorks />}/>
                <Route path="/services"  element={<Services />}/>
                <Route path="/contactus"  element={<ContactUs />}/>
                <Route path="/login"  element={<Login />}/>
                <Route path="/login/forget_password"  element={<ForgetPassword />}/>
                <Route path="/login/onetime_password"  element={<OneTimePassword />}/>
                <Route path="/login/reset_password"  element={<ResetPassword />}/>
                <Route path="/register_client"  element={<RegisterClient />}/>
                <Route path="/register_client/verify_email"  element={<VerifyEmail />}/>
                <Route path="/register_provider"  element={<RegisterProvider />}/>
                <Route path="/register_provider/personal_info"  element={<PersonalInfo />}/>
                <Route path="/register_provider/account_verification"  element={<AccountVerification />}/>

                <Route element={<PrivateRoute/>} >
                <Route path="/dashboard"  element={<Dashboard />}/>
                    <Route path="/dashboard/notifications"  element={<Notifications />}/>
                    <Route path="/messages"  element={<Messages />}/>
                    <Route path="/tasks"  element={<Tasks />}/>
                    <Route path="/favourites"  element={<Favourites />}/>
                    <Route path="/payment_history"  element={<PaymentsHistory />}/>
                    <Route path="/payment_method"  element={<PaymentsMethod />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
