import "./style.css";
import user_icon from "../../../assets/images/user.svg";
import {useRef, useState} from "react";
import axios from "axios";
import config from "../../../config";

export default function ContactUsArea() {
    const [contact, setContact] = useState({
        name:"",
        email: "",
        phone:"",
        message: "",
    })
    const [error, setError] = useState("")
    const [contactBtn, setContactBtn] = useState(false)
    const [value,setValue]=useState("")
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const messageRef = useRef(null);

    const handleChangeContact = (e, name) => {
        setContact({...contact, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(contact.email).toLowerCase());
    }
    const ContactHandler = () => {
        let values={
            name:contact.name,
            email: contact.email,
            phone:contact.phone,
            message:contact.message
        }
        setContactBtn(true)
        if (contact.email && contact.name && contact.phone && contact.message) {
            if (contact.name.length >2) {
                if (validateEmail()) {
                    axios.post(`${config.url}/contact-us`,values )
                        .then(response => {
                            console.log(response,"response");
                            nameRef.current.value = "";
                            emailRef.current.value = "";
                            phoneRef.current.value = "";
                            messageRef.current.value = "";
                        })
                        .catch(error => {
                            console.log(error.response,"error")
                            setError(error.response.data.message)
                        })
                } else {
                    setError("Incorrect email address")
                }
            } else {
                setError("Incorrect name")
            }
        }
    }
    return (
        <div className="contact_us_block margin_top_48 d_flex fd_column justify_content_center bc_white">
            <div
                className="d_flex justify_content_space_between align_items_start contact_us_block1 m">
                <div className="contact_us_block1_1 d_flex fd_column justify_content_start">
                    <div className="contact_us_block1_1_block d_flex fd_column justify_content_start">
                        <label htmlFor="" className="fs_16 f_500 c_grey text_capitalize">Our name</label>
                        <input type="text" placeholder="Enter name" ref={nameRef}
                               style={!contact.name && contactBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChangeContact(e.target.value, "name")}
                        />
                        <img src={user_icon} alt="user_icon"/>
                    </div>
                    <div className="contact_us_block1_1_block d_flex fd_column justify_content_start">
                        <label htmlFor="" className="fs_16 f_500 c_grey text_capitalize">Email Address</label>
                        <input type="email" placeholder="Enter email" ref={emailRef}
                               style={!contact.email && contactBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChangeContact(e.target.value, "email")}
                        />
                        <img src={user_icon} alt="user_icon"/>
                    </div>
                    <div className="contact_us_block1_1_block d_flex fd_column justify_content_start">
                        <label htmlFor="" className="fs_16 f_500 c_grey text_capitalize">Phone Number</label>
                        <input type="text" placeholder="Enter phone number" ref={phoneRef}
                               style={!contact.phone && contactBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChangeContact(e.target.value, "phone")}
                        />
                        <img src={user_icon} alt="user_icon"/>
                    </div>
                </div>
                <div className="contact_us_block1_2 d_flex fd_column">
                    <label htmlFor="" className="fs_16 f_500 c_grey text_capitalize">message</label>
                    <textarea name="" id="" cols="30" rows="20" ref={messageRef} placeholder="Text Here..."
                              style={!contact.message && contactBtn ? {border: "1px solid red"} : null}
                              onChange={(e) => handleChangeContact(e.target.value, "message")}
                    />
                </div>
            </div>
            <div className="contact_us_block2 d_flex fd_column justify_content_center align_items_center margin_top_48" onClick={ContactHandler}>
                {error ? <p className="error">{error}</p> : null}
                <button className="bc_blue c_white fs_14 f_500" >Send message</button>
            </div>
        </div>
    )

}