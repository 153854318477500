import "./style.css";
import house_cleaning_pic from "../../../../assets/images/house cleaning_pic.svg";
import handyman_pic from "../../../../assets/images/handyman_pic.svg";
import electrician_pic from "../../../../assets/images/electrician_pic.svg";
import designer_pic from "../../../../assets/images/designer_pic.svg";
import Writer_pic from "../../../../assets/images/writer_pic.svg";
import laundry_pic from "../../../../assets/images/laundry_pic.svg";

export default function HowWorkBlock3() {
    return (
        <div className="HowWorkBlock3_cont margin_bottom_96">
            <h2 className="fs_38 c_blue f_600 text_center">Post your Task</h2>
            <p className="text_center fs_18 f_500">Posting a Task is easy. Simply enter a Task description, set your
                location, and post your Task while waiting for an interested Artisan.</p>
            <div className="d_grid grid_columns_3fr HowWorkBlock3_cont_blocks margin_top_48">
                <img src={house_cleaning_pic} alt="house_cleaning_pic" className=""/>
                <img src={handyman_pic} alt="handyman_pic"/>
                <img src={electrician_pic} alt="electrician_pic"/>
                <img src={designer_pic} alt="designer_pic"/>
                <img src={Writer_pic} alt="Writer_pic"/>
                <img src={laundry_pic} alt="laundry_pic"/>
            </div>
        </div>
    )
}