import "./style.css";
import SidebarComp from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import notification_page_pic from "../../assets/images/notification_page_pic.svg";

export default function Notifications(){
    return (
        <div className="d_flex">
            <div>
                <SidebarComp/>
            </div>
            <div className="main_dashboard">
                <Header isLogin={true}/>
                <div className="container_dashboard">
                    <div className="container_inner">
                        <div className="dashboard_b1 d_flex fd_column justify_content_center align_items_center">
                            <div className="dashboard_b1_1 d_flex justify_content_space_between">
                                <p className="f_400 fs_36 c_black" style={{color:"#3C3C3C"}}>Notifications</p>
                                <div className="d_flex align_items_center">
                                    <button className="dash_post_btn c_white f_500 fs_16">Post A Task</button>
                                    <button className="view_task_btn c_white fs_16 f_500">View Task</button>
                                </div>
                            </div>
                            <div className="dashboard_b1_2">
                                <img src={notification_page_pic} alt=""/>
                                <p className="fs_18 f_500 text_center">This is where we'll let you know about tasks, comments and other stuff.
                                    Let's post a task or make an offer!</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}