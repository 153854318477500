import "./style.css";
import React, {useRef, useState} from "react";
import folder_add from "../../../../../../assets/images/folder-add.png";
import {FileUploader} from "react-drag-drop-files";
import {head} from "axios";
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import {getDroppedOrSelectedFiles} from 'html5-file-selector'
import ImageUpload from "../../../identification/upload_image/UploadImage";

const fileTypes = ["JPEG", "PNG", "JPG"];

export default function DragDropFile(props) {
    const [file, setFile] = useState();
    const handleChange = (file) => {
        setFile(file[0]);
        props.setFileUpload(true)
    };

    return (
        <>
            <div className="drag_drop_cont">
                <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    className="file_uploader d_flex justify_content_center align_items_center"
                />
                <div className="upload_b d_flex fd_column justify_content_center align_items_center">
                    <p className="text_center f_600 fs_16 c_blue">Select a File Upload</p>
                    <p className="fs_14 f_500 c_grey drop-title">Or Drag and Drop It here</p>
                </div>
            </div>
            <div className="drag_drop_btn">
                <ImageUpload fileOut={file} setFile={setFile} fileUpload={props.fileUpload} setFileUpload={props.setFileUpload}/>
                {/*    <p className="file-name text_center">{file ? `File name: ${file.name}` : "no files uploaded yet"}</p>*/}
            </div>
        </>
    );
};