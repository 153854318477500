import "./style.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import about_yourself_banner from "../../assets/images/about_yourself_banner.svg";
import about_yourself_fon from "../../assets/images/about_yourself_fone.svg";
import {useNavigate} from "react-router-dom";

export default function RegisterAs(){
    let navigate=useNavigate()
    return(
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="home_cont_b1 post_task_cont">
                    <img src={about_yourself_banner} alt="home_general_pic" className="home_general_pic"/>
                    <img src={about_yourself_fon} alt="home_general_pic_fon" className="home_general_pic_fon"/>
                    <div className="postTask_cont_b1_text_block text_center d_flex fd_column justify_content_center align_items_center">
                        <h1 className="c_white f_700 fs_48 margin_bottom_96">Tell us about Yourself</h1>
                        <button className="bc_blue c_white fs_16 f_500" onClick={()=>navigate('/register_provider')}>A professional, I can help with your task</button>
                        <button className="bc_green c_white fs_16 f_500 margin_top_48" onClick={()=>navigate('/register_client')}>A client in need of a skill provider</button>
                    </div>
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}