import "./style.css";
import {useEffect, useRef, useState} from "react";
import dropDawn_arrow_icon from "../../../../assets/images/arrow_down_grey.svg";
import valid_pic from "../../../../assets/images/submit_valid_id.svg";
import ImageUpload from "./upload_image/UploadImage.js";


export default function Identification(props) {
    let [documentState, setDocumentState] = useState([
        {
            id: 0,
            type: "Select",
            status: true,
        },
        {
            id: 1,
            type: "international passport",
            status: false,
        },
        {
            id: 2,
            type: "driver’s license",
            status: false,
        },
        {
            id: 3,
            type: "national ID card",
            status: false,
        },
        {
            id: 4,
            type: "international passport",
            status: false,
        },
        {
            id: 5,
            type: "international passport",
            status: false,
        },
    ])
    let [showDocument, setShowDocument] = useState(false)
    let [error, setError] = useState("")
    let [showUpload, setShowUpload] = useState(false)
    let chooseDocumentRef = useRef()
    let closeDocumentRef = useRef()

    const handleClickDocumentDropDawn = () => {
        setShowDocument(!showDocument)
    }
    const handleClickOutside = (e) => {
        let documentRef = chooseDocumentRef;
        let closeDocRef = closeDocumentRef;
        if (documentRef.current && !documentRef.current.contains(e.target) && !closeDocRef.current.contains(e.target)) {
            setShowDocument(false);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);
    let changeDocument = (index) => {
        let documentArr = documentState;
        documentArr.map(data => {
            data.status = false
        })
        documentArr[index].status = true
        setShowDocument(false)
        setDocumentState([...documentArr])
    }
    return (
        <div className="container">
            <div className="container_inner">
                <div className="register_details_cont bc_white">
                    <div className="identification_cont">
                        <h2 className="text_center fs_24 c_grey">Upload your KYC ( Valid means of Identification)
                            documents</h2>
                        <div className="c_red text_center">Valid documents: Driver’s Licence,International
                            passport,National Identitty card, Voter’s card
                        </div>
                        <div
                            className="d_flex fd_column justify_content_center align_items_center valid_pic_block margin_top_48">
                            <h2 className="fs_38 f_600 c_blue text_center">Submit valid ID</h2>
                            <img src={valid_pic} alt=""/>
                        </div>
                        <div className="d_flex fd_column valid_content_block">
                            <div className="register_details_cont_block d_flex fd_column">
                                <label htmlFor="" className="fs_16 f_500">Select document type</label>
                                <div className="dropdown">
                                    <div
                                        className="dropBtn select_block d_flex justify_content_space_between align_items_center"
                                        onClick={handleClickDocumentDropDawn} ref={closeDocumentRef}>
                                        <div>
                                            {
                                                documentState.map((item, index) => {
                                                    if (item.status) {
                                                        return (
                                                            <div key={item.id}
                                                                 className="dropdown_item text_capitalize fs_16 f_500">{item.type}</div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>

                                        {
                                            !showDocument ?
                                                <img src={dropDawn_arrow_icon} alt="dropDawn_arrow_icon"
                                                     className="dropDawn_arrow_bottom"/>
                                                :
                                                <img src={dropDawn_arrow_icon} alt="dropDawn_arrow_icon"
                                                     className="dropDawn_arrow_top"/>
                                        }
                                    </div>
                                    {
                                        showDocument ?
                                            <div className="dropdown_container" ref={chooseDocumentRef}>
                                                <div className="dropdown_inner">
                                                    {
                                                        documentState.map((item, index) => {
                                                            return (
                                                                <div key={item.id}
                                                                     className="dropdown_item text_capitalize fs_16 f_500"
                                                                     onClick={() => changeDocument(index)}>{item.type}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="register_details_cont_block d_flex fd_column">
                                <label htmlFor="" className="fs_16 f_500">input ID number</label>
                                <input type="text" placeholder="" className="fs_16 f_500"/>
                            </div>
                            <div className="d_flex justify_content_space_between align_items_center">
                                <ImageUpload changeStep={props.changeStep} file={props.file} setFile={props.setFile}/>
                            </div>
                        </div>


                        <div className="d_flex  fd_column justify_content_center align_items_center margin_top_48">
                            {error ? <p className="error">{error}</p> : null}
                            <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn"
                                    onClick={() => props.changeStep('next')}>Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}