import "./style.css";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import config from "../../config";
import Header from "../../components/header/Header";
import email_icon from "../../assets/images/sms.svg";


export default function ForgetPassword(){
    let navigate = useNavigate();
    let [show, setShow] = useState(false)
    const [forgot, setForgot] = useState({
        email: "",
    })
    const [error, setError] = useState("")
    const [forgotBtn, setForgotBtn] = useState(false)

    const handleChangeForgot = (e, name) => {
        setForgot({...forgot, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(forgot.email).toLowerCase());
    }
    const forgotHandler = () => {
        let values={
            email: forgot.email,
        }
        setForgotBtn(true)
        if (forgot.email.length) {
                if (validateEmail()) {
                    console.log(values);
                    axios.post(`${config.url}/password/email`,values )
                        .then(response => {
                            console.log(response,"response");
                            localStorage.setItem('jwtToken',  response.data.token)
                            navigate("/login/onetime_password")
                        })
                        .catch(error => {
                            console.log(error.response,"error")
                            setError(error.response.data.message)
                        })
                } else {
                    setError("Incorrect email address")
                }
        }
    }

    return(
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="login_register_cont bc_white">
                            <h1 className="text_capitalize text_center fs_38 f_600">Forgot password?</h1>
                            <div className="text_center fs_16 f_500 forget_pass_text">Fill your email and we will send you One Time Password to reset password.</div>
                            <div className="login_register_cont_blocks d_flex fd_column">
                                <div className="login_register_cont_block d_flex fd_column">
                                    <label htmlFor="" className="fs_16 f_500">Email address</label>
                                    <input type="email" placeholder="Enter email address"
                                           style={!forgot.email && forgotBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeForgot(e.target.value, "email")}
                                    />
                                    <img src={email_icon} alt="email_icon"/>
                                </div>
                            </div>
                            <div className="d_flex fd_column justify_content_center align_items_center margin_top_48">
                                {error ? <p className="error">{error}</p> : null}
                                <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn" onClick={forgotHandler}>Send OTP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}
