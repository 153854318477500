import "./style.css";
import skill_pic1 from "../../../../assets/images/skill_pic1.svg";
import skill_pic2 from "../../../../assets/images/skill_pic2.svg";
import skill_pic3 from "../../../../assets/images/skill_pic3.svg";
import skill_pic4 from "../../../../assets/images/skill_pic4.svg";
import skill_pic5 from "../../../../assets/images/skill_pic5.svg";
import skill_pic6 from "../../../../assets/images/skill_pic6.svg";
import skill_pic7 from "../../../../assets/images/skill_pic7.svg";

import {useState} from "react";

export default function BrowseSkill() {
    let [skill,setSkill]=useState([
        {
            id:1,
            img: skill_pic1,
            title:"Design & Multimedia",
            desc1: "Brand identity designs",
            desc2: "Graphics design",
            desc3: "illustration",
            desc4: "Video production & Editing",
            desc5: "UI/UX Design",
            desc6: "Logo design",
            desc7: "Photo Editing",
            btn:"View All Skills",
        },
        {
            id:2,
            img: skill_pic2,
            title:"Writing and content",
            desc1: "copywriting",
            desc2: "content creation",
            desc3: "writing",
            desc4: "Sale & marketing writing",
            desc5: "content writing",
            desc6: "technical writing",
            desc7: "editing & proofreading",
            btn:"View All Skills",
        },
        {
            id:3,
            img: skill_pic3,
            title:"Marketing $ sales",
            desc1: "DigitalMarketing",
            desc2: "e-commerce marketing",
            desc3: "email marketing",
            desc4: "Facebook advertizing",
            desc5: "lead generation",
            desc6: "Social media marketing",
            desc7: "search engine optimization (SEO)",
            btn:"View All Skills",
        },
        {
            id:4,
            img: skill_pic4,
            title:"cleaning & fumigation",
            desc1: "Household cleaning",
            desc2: "office cleaning",
            desc3: "new construction site cleaning",
            desc4: "household fumigation",
            desc5: "office fumigation",
            desc6: "industrial cleaning",
            desc7: "bush clearing",
            btn:"View All Skills",
        },
        {
            id:5,
            img: skill_pic5,
            title:"development",
            desc1: "web development",
            desc2: "javascript",
            desc3: "Mobile app development",
            desc4: "back-end development",
            desc5: "front-end development",
            desc6: "full stack development",
            desc7: "wordpress development",
            btn:"View All Skills",
        },
        {
            id:6,
            img: skill_pic6,
            title:"building construction",
            desc1: "plumbing",
            desc2: "wiring",
            desc3: "welding",
            desc4: "painting",
            desc5: "carpentary",
            desc6: "electrical work",
            desc7: "iron benders",
            btn:"View All Skills",
        },
        {
            id:7,
            img: skill_pic7,
            title:"admin & IT support",
            desc1: "data entry",
            desc2: "email, phone and chat support",
            desc3: "project management",
            desc4: "virtual assistant",
            desc5: "customer service",
            desc6: "data processing",
            desc7: "Customer relatonship management (CRM)",
            btn:"View All Skills",
        },
        {
            id:8,
            img: skill_pic7,
            title:"design & creatives",
            desc1: "makeup artist",
            desc2: "hair styling",
            desc3: "photography & videography",
            desc4: "fashion designing",
            desc5: "event planning",
            desc6: "pasteries & cafeteria",
            desc7: "chef / baker",
            btn:"View All Skills",
        },

    ])
    return (
        <div className="container">
            <div className="container_inner">
                <div className="browse_skill_cont bc_white ">
                    <h2 className="fs_38 f_600 c_blue text_center">BROWSE BY SKILL</h2>
                    <div className="d_grid grid_columns_4fr browse_skill_cont_items margin_top_48 grid_gab_50">
                        {
                            skill.map((item,index)=>{
                                return(
                                    <div className="browse_skill_cont_item d_flex fd_column align_items_center justify_content_center text_center" key={item.id}>
                                        <img src={item.img} alt="img"/>
                                        <h3 className="fs_24 f_500 c_black text_capitalize">{item.title}</h3>
                                        <span className="text_capitalize fs_16 f_500 c_grey">{item.desc1}</span>
                                        <span className="text_capitalize fs_16 f_500 c_grey">{item.desc2}</span>
                                        <span className="text_capitalize fs_16 f_500 c_grey">{item.desc3}</span>
                                        <span className="text_capitalize fs_16 f_500 c_grey">{item.desc4}</span>
                                        <span className="text_capitalize fs_16 f_500 c_grey">{item.desc5}</span>
                                        <span className="text_capitalize fs_16 f_500 c_grey">{item.desc6}</span>
                                        <span className="text_capitalize fs_16 f_500 c_grey">{item.desc7}</span>
                                        <button className="fs_16 f_500 c_blue margin_top_48">{item.btn}</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}