import "./style.css";
import {useState} from "react";
import registration_pic from "../../../../assets/images/registration_icon.svg";
import post_task_icon from "../../../../assets/images/post_task_icon.png";
import get_work_done_icon from "../../../../assets/images/get_work_done_icon.svg";
import get_payment_icon from "../../../../assets/images/secure_payment_icon.png"
export default function GetWork() {
    let [getWork, setGetWork] = useState([
        {
            id:1,
            img:registration_pic,
            title: "Registration",
            desc: "register as a client or skill provider and provider neccessary document for verification",
        },
        {
            id:2,
            img:post_task_icon,
            title: "Post a task",
            desc: "Create fee posting and choose from list of competent skill providers available for the task",
        },
        {
            id:3,
            img:get_work_done_icon,
            title: "Get work done",
            desc: "decide who to work on your Task as a client and decide your conveniency  as a skill provider",
        },
        {
            id:4,
            img:get_payment_icon,
            title: "Make secure payment",
            desc: "Choose from multiple payment gateway to make payment as a client and get paid after task completed",
        },
    ])
    return (
        <div className="get_work_cont margin_bottom_96">
            <div className="container">
                <div className="container_inner">
                    <h1 className="c_blue fs_38 text_center f_600">it ‘s easy to get work done on everiwork</h1>
                    <div className="get_work_cont_items d_grid grid_columns_4fr grid_gab_35 margin_top_48">
                        {
                            getWork.map((item,index)=>{
                                return(
                                    <div className="get_work_cont_item d_flex fd_column justify_content_center align_items_center" key={item.id}>
                                        <img src={item.img} alt="registration_pic"/>
                                        <h2 className="fs_28 f_600 text_center">{item.title}</h2>
                                        <div className="text_capitalize text_center fs_16 f_500">{item.desc}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}