import "./style.css"
import SidebarComp from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import tasks_page_pic from "../../assets/images/tasks_page_pic.png";
import {useState} from "react";
import VSelectStatus from "./components/v_select_status/VSelectStatus";
import VSelectDate from "./components/v_select_date/VSelectDate";

export default function Tasks() {
    let [statusState, setStatusState] = useState("All")
    let [dateState, setDateState]=useState("Today")
    let [filterData, setFilterData] = useState({})
    let [filterItems, setFilterItems] = useState({})
    let [table, setTable]=useState([
        {

        }
        ])

    let changeFilterData = (data) => {
        setFilterData({...filterData, ...data})
    }

    return (
        <div className="d_flex">
            <div>
                <SidebarComp/>
            </div>
            <div className="main_dashboard">
                <Header isLogin={true}/>
                <div className="container_dashboard">
                    <div className="container_inner">
                        <div className="dashboard_b1 d_flex fd_column">
                            <div className="dashboard_b1_1 d_flex justify_content_space_between">
                                <p className="f_400 fs_36 c_black" style={{color: "#3C3C3C"}}>Tasks</p>
                                <div className="d_flex align_items_center">
                                    <button className="dash_post_btn c_white f_500 fs_16">Post A Task</button>
                                    <button className="view_task_btn c_white fs_16 f_500">View Task</button>
                                </div>
                            </div>
                            <div className="d_flex justify_content_start align_items_start filters_b">
                                <div className="d_flex fd_column filters_b_item">
                                    <label htmlFor="" className="fs_14 f_500 c_grey">Filter by Status</label>
                                    {/*<select name="" id="">*/}
                                    {/*    <option value="">All</option>*/}
                                    {/*</select>*/}
                                    <VSelectStatus placeholder="All"
                                                   status={filterItems?.status}
                                                   statusState={statusState}
                                                   changeFilterData={changeFilterData}
                                                   setStatusState={setStatusState}/>
                                </div>


                                <div className="d_flex fd_column filters_b_item">
                                    <label htmlFor="" className="fs_14 f_500 c_grey">filter by date</label>
                                    {/*<select name="" id="">*/}
                                    {/*    <option value="">Today</option>*/}
                                    {/*</select>*/}
                                    <VSelectDate placeholder="Today"
                                                  data={filterItems?.data}
                                                   dateState={dateState}
                                                   changeFilterData={changeFilterData}
                                                   setDateState={setDateState}/>
                                </div>
                            </div>
                            {
                                !filterData ?
                                    <div
                                        className="dashboard_b1_2 d_flex fd_column justify_content_center align_items_center margin_top_48">
                                        <img src={tasks_page_pic} alt=""/>
                                        <p className="fs_18 f_500 text_center">No Records Found.</p>
                                    </div>
                                    :
                                   <div className="table_cont">
                                       <table id="table" className="table">
                                           <tr className="">
                                               <th className="text_left">S/N</th>
                                               <th className="text_left">Date</th>
                                               <th className="text_left">Name</th>
                                               <th className="text_left">Job description</th>
                                               <th className="text_left">Amount</th>
                                               <th className="text_left">Status</th>
                                           </tr>
                                           <tr>
                                               <td>1</td>
                                               <td>11/09/2022</td>
                                               <td className="text_capitalize">Mary Sokoh</td>
                                               <td className="text_capitalize">Graphics Designer</td>
                                               <td className="text_capitalize">#120,000</td>
                                               <td className="text_capitalize">Completed</td>
                                           </tr>
                                           <tr>
                                               <td>1</td>
                                               <td>11/09/2022</td>
                                               <td className="text_capitalize">Mary Sokoh</td>
                                               <td className="text_capitalize">Graphics Designer</td>
                                               <td className="text_capitalize">#120,000</td>
                                               <td className="text_capitalize">Completed</td>
                                           </tr>
                                           <tr>
                                               <td>1</td>
                                               <td>11/09/2022</td>
                                               <td className="text_capitalize">Mary Sokoh</td>
                                               <td className="text_capitalize">Graphics Designer</td>
                                               <td className="text_capitalize">#120,000</td>
                                               <td className="text_capitalize">Completed</td>
                                           </tr>
                                           <tr>
                                               <td>1</td>
                                               <td>11/09/2022</td>
                                               <td className="text_capitalize">Mary Sokoh</td>
                                               <td className="text_capitalize">Graphics Designer</td>
                                               <td className="text_capitalize">#120,000</td>
                                               <td className="text_capitalize">Completed</td>
                                           </tr>
                                           <tr>
                                               <td>1</td>
                                               <td>11/09/2022</td>
                                               <td className="text_capitalize">Mary Sokoh</td>
                                               <td className="text_capitalize">Graphics Designer</td>
                                               <td className="text_capitalize">#120,000</td>
                                               <td className="text_capitalize">Completed</td>
                                           </tr>
                                           <tr>
                                               <td>1</td>
                                               <td>11/09/2022</td>
                                               <td className="text_capitalize">Mary Sokoh</td>
                                               <td className="text_capitalize">Graphics Designer</td>
                                               <td className="text_capitalize">#120,000</td>
                                               <td className="text_capitalize">Completed</td>
                                           </tr>

                                       </table>
                                   </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}