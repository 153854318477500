import "./style.css";
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';


export default function  Pagination({  heroes, onChangePage }){
    const pageCount = Math.ceil(heroes.length/ 6);

    const handlePageClick = (event) => {

    };

    return(
        <div>
            <div className="d_flex justify_content_center align_items_center">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onClick={(e) => {
                        onChangePage(e)
                    }}
                    onPageChange={(e) => {
                        if( 0 < e.selected|| e.selected <= pageCount -1 ){
                            onChangePage({nextSelectedPage: e.selected})
                        }
                    }}
                    pageRangeDisplayed={0}
                    pageCount={pageCount}
                    heroes={heroes}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className="pagination"
                />
            </div>
        </div>
    )
}