import "./style.css";
import Header from "../../components/header/Header";
import profile_icon from "../../assets/images/profile-circle.svg";
import email_icon from "../../assets/images/sms.svg";
import facebook_icon from "../../assets/images/facebook_icon.svg";
import google_icon from "../../assets/images/google_icon.svg";
import dropDawn_arrow_icon from "../../assets/images/arrow_down_grey.svg";
import {useNavigate} from "react-router-dom";
import {useRef, useState, useEffect} from "react";
import RegisterProviderSteps from "./components/register_provider_steps/RegisterProviderSteps";
import PersonalInformation from "./components/personal_information/PersonalInformation";


export default function PersonalInfo() {
    return (
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <RegisterProviderSteps/>
            </div>
            <div>

            </div>
        </div>
    )
}