import "./style.css"
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import about_pic_back_fon from "../../assets/images/about_pics_back_fone.svg";
import about_b1_pic from "../../assets/images/about_b1_pic.svg";
import about_b2_pic from "../../assets/images/about_b2_pic.svg";
import about_b3_pic from "../../assets/images/about_b3_pic.svg";
import CoreValues from "./components/core_values/CoreValues";
import Superheroes from "./components/superheroes/Superheroses";


export default function AboutUs() {
    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="about_us_cont">
                            <div className="about_container_b1 d_flex align_items_center">
                                <div className="about_container_b1_img_b">
                                    <img src={about_b1_pic} alt="about_pic" className="about_pic"/>
                                    <img src={about_pic_back_fon} alt="about_pic_fon" className="about_pic_fon"/>
                                </div>
                                <div className="about_container_b1_text_b">
                                    <h1 className="c_blue fs_38 f_600">About Evriwork</h1>
                                    <div className="fs_18 f_500 c_black">Evriwork is a verified platform that on connecting
                                        people with soft to clients. It
                                        is an easy to use and platform that provides job seekers great opportunity to
                                        showcase their in the market. The platform will relationships with clients by
                                        providing them the structure for their oursourced projects and act as a platform
                                        collaboration between dierent freelancers of dierent skills.
                                    </div>
                                </div>
                            </div>
                            <div className="about_container_b2 d_flex align_items_center">
                                <div className="about_container_b2_text_b">
                                    <h1 className="c_blue fs_38 f_600">Our Mission</h1>
                                    <div className="fs_18 f_500 c_black">At Evriwork, we are committed to connecting service
                                        providers and customers. Because this is a great opportunity to build a bond between
                                        the two of you. We build relationships with our clients by providing them with a
                                        structure for outsourced projects and acting as a platform for collaboration between
                                        different freelancers with different skill sets.
                                    </div>
                                </div>
                                <div className="about_container_b1_img_b">
                                    <img src={about_b2_pic} alt="about_pic" className="about_pic"/>
                                    <img src={about_pic_back_fon} alt="about_pic_fon" className="about_pic_fon"/>
                                </div>
                            </div>
                            <div className="about_container_b3 d_flex align_items_center">
                                <div className="about_container_b1_img_b">
                                    <img src={about_b3_pic} alt="about_pic" className="about_pic"/>
                                    <img src={about_pic_back_fon} alt="about_pic_fon" className="about_pic_fon"/>
                                </div>
                                <div className="about_container_b1_text_b">
                                    <h1 className="c_blue fs_38 f_600">Our Vision</h1>
                                    <div className="fs_18 f_500 c_black">Our vision is to create a secure community where
                                        customers and service providers can easily connect.
                                    </div>
                                </div>
                            </div>
                            <CoreValues/>
                        </div>
                    </div>
                </div>
                <Superheroes/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}