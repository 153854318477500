import "./style.css";
import { useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import config from "../../config";
import Header from "../../components/header/Header";
import key_icon from "../../assets/images/key.svg";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";


export default function ResetPassword(){
    let navigate = useNavigate();
    let history = useLocation();
    let [show, setShow] = useState(false)
    let [showConfirm, setShowConfirm] = useState(false)
    const [reset, setReset] = useState({
        password: "",
        c_password: "",
        code: history.state.code

    })
    const [error, setError] = useState("")
    const [resetBtn, setResetBtn] = useState(false)
    let showHidePass = (e) => {
        setShow(!show)
    }
    let showHideConfirmPass = (e) => {
        setShowConfirm(!showConfirm)
    }
    const handleChangeReset = (e, name) => {
        setReset({...reset, [name]: e})
        setError("")
    }

    const resetHandler = () => {
        let values={
            password: reset.password,
            c_password:reset.c_password,
            code:reset.code
        }
        setResetBtn(true)
        if (reset.password.length && reset.c_password.length) {
            if(reset.password.length>7){
            if(reset.password === reset.c_password) {
                console.log(values,"etetg")
                    axios.post(`${config.url}/password/reset`, values)
                        .then(response => {
                            console.log(response, "response");
                            localStorage.setItem('jwtToken', response.data.token)
                            navigate("/login")
                        })
                        .catch(error => {
                            console.log(error.response, "error")
                            setError(error.response.data.message)
                        })
            }
            else{
                    setError("Passwords are different")
                }
            }else {
                setError("Password length is small")
            }
        }
    }

    return(
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="login_register_cont bc_white">
                            <h1 className="text_capitalize text_center fs_38 f_600">Reset Password</h1>
                            <div className="login_register_cont_block d_flex fd_column  forget_password_cont_block">
                                <label htmlFor="" className="fs_16 f_500">New Password</label>
                                <input type={show ? "text" : "password"} placeholder="New Password"
                                       style={!reset.password && resetBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChangeReset(e.target.value, "password")}
                                />
                                <img src={key_icon} alt="key_icon"/>
                                {show ? <AiOutlineEye className="hide_icon fs_16" onClick={showHidePass}/> :
                                    <AiOutlineEyeInvisible className="hide_icon fs_16" onClick={showHidePass}/>
                                }
                            </div>
                            <div className="login_register_cont_block d_flex fd_column">
                                <label htmlFor="" className="fs_16 f_500">Repeat New Password</label>
                                <input type={showConfirm ? "text" : "password"} placeholder="Repeat New Password"
                                       style={!reset.c_password && resetBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChangeReset(e.target.value, "c_password")}
                                />
                                <img src={key_icon} alt="key_icon"/>
                                {showConfirm ?
                                    <AiOutlineEye className="hide_icon fs_16" onClick={showHideConfirmPass}/> :
                                    <AiOutlineEyeInvisible className="hide_icon fs_16"
                                                           onClick={showHideConfirmPass}/>
                                }
                            </div>
                            <div className="d_flex fd_column justify_content_center align_items_center margin_top_48">
                                {error ? <p className="error">{error}</p> : null}
                                <button className="bc_blue c_white fs_16 f_500 login_register_cont_sign_btn" onClick={resetHandler}>Save password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}
